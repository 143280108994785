import { ChartDataItem } from '../../../../hooks/client/dashboard/interfaces/dashboard-data-item.interface';
import { HeaderItem, HeaderItemProps } from './header-item';

export const SalesMarketingDiagramHeader = ({
  headerMetrics,
}: {
  headerMetrics: {
    salesMarketingExpenses: ChartDataItem;
    newRevenue: ChartDataItem;
    wonDeals: ChartDataItem;
    mql: ChartDataItem;
    conversionMqlWon: ChartDataItem;
    ltvCac: ChartDataItem;
  };
}) => {
  if (!headerMetrics) {
    return null;
  }

  const items: HeaderItemProps[] = [
    {
      name: '💰 S&M EXPENSES, $',
      value: headerMetrics.salesMarketingExpenses,
      valueType: 'sum',
    },
    {
      name: '💰 NEW REVENUE, $',
      value: headerMetrics.newRevenue,
      valueType: 'sum',
    },
    {
      name: '📄 WON DEALS, #',
      value: headerMetrics.wonDeals,
      valueType: 'number',
    },
    {
      name: '🧲 MQL, #',
      value: headerMetrics.mql,
      valueType: 'number',
    },
    {
      name: '💫 CONV MQl→WON,%',
      value: headerMetrics.conversionMqlWon,
      valueType: 'percent',
    },

    {
      name: '📍LTV : CAC',
      value: headerMetrics.ltvCac,
      valueType: 'ratio',
    },
  ];

  return (
    <div className={'header-holder'}>
      {items.map((item, index) => {
        return (
          <HeaderItem
            key={index}
            name={item.name}
            value={item.value}
            valueType={item.valueType}
          />
        );
      })}
    </div>
  );
};
