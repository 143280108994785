import { SummaryDataset } from '../interfaces/dashboard-card.type';
import {
  DashboardValueBuilderv2,
  formatDate,
} from '../../../../utils/dashboard/formatters';
import {
  DashboardDataItemWithCategory,
  DashboardDataItemWithStage,
} from '../interfaces/dashboard-data-item.interface';
import {
  calculatePercentageChange,
  getLastClosedMonth,
  getLongestDateRange,
} from '../utils';
import {
  SalesMarketingDashboardData,
  SalesMarketingDashboardDataType,
} from '../interfaces/sales-marketing-dashboard-data.interface';
import { BaseDashboardManager } from './base-dashboard-data.manager';

export class SalesMarketingDashboardDataManager extends BaseDashboardManager<
  SalesMarketingDashboardData,
  SalesMarketingDashboardDataType
> {
  constructor(protected readonly dashboardData: SalesMarketingDashboardData) {
    super(dashboardData);
    this.methodMap = new Map<SalesMarketingDashboardDataType, any>([
      [SalesMarketingDashboardDataType.SM_NEW_REVENUE, this.getNewRevenueData],
      [SalesMarketingDashboardDataType.SM_WON_DEALS, this.getWonDealsData],
      [
        SalesMarketingDashboardDataType.EXPENSES,
        this.getExpensesBreakdownActualProjected,
      ],
      [
        SalesMarketingDashboardDataType.SALES_MARKETING_EXPENSES,
        this.getSalesMarketingExpensesData,
      ],
      [
        SalesMarketingDashboardDataType.SM_REVENUE_GROWTH,
        this.getSalesMarketingExpensesGrowth,
      ],
      [
        SalesMarketingDashboardDataType.AVG_REVENUE_GROWTH,
        this.getAvgRevenueSummaryData,
      ],
      [
        SalesMarketingDashboardDataType.AVG_EXPENSES_GROWTH,
        this.getAvgExpensesSummaryData,
      ],
      [SalesMarketingDashboardDataType.LTV_CAC_COMBINED, this.getLtvCacData],
      [
        SalesMarketingDashboardDataType.CONVERSION_MQL_WON,
        this.getConversionMqlWonData,
      ],
      [SalesMarketingDashboardDataType.MQL, this.getMqlCustomersData],
      [SalesMarketingDashboardDataType.COST_PER_MQL, this.getCostPerMqlData],
      [SalesMarketingDashboardDataType.SQL, this.getSqlCustomersData],
      [SalesMarketingDashboardDataType.COST_PER_SQL, this.getCostPerSqlData],
      [SalesMarketingDashboardDataType.MQL_SUMMARY, this.getMqlSummaryData],
      [
        SalesMarketingDashboardDataType.PER_MQL_SUMMARY,
        this.getPerMqlSummaryData,
      ],
      [SalesMarketingDashboardDataType.SQL_SUMMARY, this.getSqlSummaryData],
      [
        SalesMarketingDashboardDataType.PER_SQL_SUMMARY,
        this.getPerSqlSummaryData,
      ],
      [
        SalesMarketingDashboardDataType.CONVERSION_MQL_SQL_SUMMARY,
        this.getMqlSqlConversionSummaryData,
      ],
      [
        SalesMarketingDashboardDataType.CONVERSION_MQL_SQL,
        this.getConversionMqlSqlData,
      ],
      [
        SalesMarketingDashboardDataType.SALES_NEW_REVENUE,
        this.getNewRevenueData,
      ],
      [
        SalesMarketingDashboardDataType.NEW_REVENUE_SUMMARY,
        this.getNewRevenueSummaryData,
      ],
      [
        SalesMarketingDashboardDataType.WON_DEALS_SUMMARY,
        this.getWonDealsSummaryData,
      ],
      [
        SalesMarketingDashboardDataType.AVG_DEAL_VALUE_SUMMARY,
        this.getAverageDealValueSummaryData,
      ],
      [SalesMarketingDashboardDataType.SALES_WON_DEALS, this.getWonDealsData],
      [
        SalesMarketingDashboardDataType.AVG_DEAL_VALUE,
        this.getAverageDealValueData,
      ],
      [SalesMarketingDashboardDataType.SALES_CYCLE, this.getSalesCycleData],
      [SalesMarketingDashboardDataType.LOST_DEALS, this.getLostDealsData],
      [SalesMarketingDashboardDataType.LOST_VALUE, this.getLostValueData],
      [
        SalesMarketingDashboardDataType.SOURCES_NEW_REVENUE_SUMMARY,
        this.getNewRevenueSummaryData,
      ],
      [
        SalesMarketingDashboardDataType.CONVERSION_MQL_WON_SUMMARY,
        this.getMqlWonConversionSummaryData,
      ],
      [SalesMarketingDashboardDataType.ROI_SUMMARY, this.getRoiSummaryData],
      [
        SalesMarketingDashboardDataType.SALES_CYCLE_BY_STAGE,
        this.getSalesCycleByStageData,
      ],
      [
        SalesMarketingDashboardDataType.SALES_FUNNEL_DEALS_NUMBER,
        this.getSalesFunnelDealsNumber,
      ],
      [
        SalesMarketingDashboardDataType.SALES_FUNNEL_DEAL_VALUE,
        this.getDealValueByStageData,
      ],
      [
        SalesMarketingDashboardDataType.SALES_FUNNEL_CONVERSION_RATES,
        this.getConversionRatesByStageData,
      ],
    ]);
  }

  private getNewRevenueData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.newRevenueActualMetrics,
      [],
      'actual, $',
      'projected, $'
    );
  };

  private getWonDealsData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.newWonCustomersMetrics,
      [],
      'actual, #',
      'projected, #'
    );
  };

  private getSalesCycleData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.averageDaysToCloseMetrics,
      [],
      'avg days to close',
      'projected'
    );
  };

  private getSalesMarketingExpensesGrowth = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.acquistionCostsGrowthMetrics,
      this.dashboardData.revenueGrowthActualMetrics,
      'S&M expenses growth, %',
      'revenue growth, %'
    );
  };

  private getAverageDealValueData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.averageNewWonDealValueMetrics,
      [],
      'actual',
      ''
    );
  };

  private getLostDealsData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.newLostCustomersMetrics,
      [],
      'lost deals, #',
      ''
    );
  };

  private getLostValueData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.averageLostDealValueMetrics,
      [],
      'lost deals, $',
      ''
    );
  };

  private getLtvCacData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.cacActualMetrics,
      this.dashboardData.ltvMetrics,
      'CAC',
      'LTV'
    );
  };

  private getConversionMqlWonData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.mqlWonConversionRateMetrics,
      [],
      'actual',
      'projected'
    );
  };

  private getMqlCustomersData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.mqlCustomersMetrics,
      [],
      'actual',
      'projected'
    );
  };

  private getCostPerMqlData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.costPerMqlMetrics,
      [],
      'cost per MQL, $',
      ''
    );
  };

  private getSqlCustomersData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.sqlCustomersMetrics,
      [],
      'actual',
      'projected'
    );
  };

  private getCostPerSqlData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.costPerSqlMetrics,
      [],
      'cost per SQL, $',
      ''
    );
  };

  private getConversionMqlSqlData = () => {
    return this.getActualProjectedMetricData(
      this.dashboardData.mqlSqlAccumulatedConversionRateMetrics,
      [],
      'actual',
      'projected'
    );
  };

  public getAvgRevenueSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.AVG_REVENUE_GROWTH,
      'percent'
    );
  };

  public getAvgExpensesSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.AVG_EXPENSES_GROWTH,
      'percent'
    );
  };

  public getMqlSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SalesMarketingDashboardDataType.MQL_SUMMARY);
  };

  public getPerMqlSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SalesMarketingDashboardDataType.PER_SQL_SUMMARY);
  };

  public getSqlSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SalesMarketingDashboardDataType.SQL_SUMMARY);
  };

  public getPerSqlSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(SalesMarketingDashboardDataType.PER_SQL_SUMMARY);
  };

  public getMqlSqlConversionSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.CONVERSION_MQL_SQL_SUMMARY,
      'percent'
    );
  };

  public getMqlWonConversionSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.CONVERSION_MQL_WON_SUMMARY,
      'percent'
    );
  };

  public getRoiSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.ROI_SUMMARY,
      'percent'
    );
  };

  public getNewRevenueSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.NEW_REVENUE_SUMMARY
    );
  };

  public getWonDealsSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.WON_DEALS_SUMMARY
    );
  };

  public getAverageDealValueSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(
      SalesMarketingDashboardDataType.AVG_DEAL_VALUE_SUMMARY
    );
  };

  private getSalesMarketingExpensesData = () => {
    return this.prepareTableData('totalExpenseBySalesMarketingCategoryMetrics');
  };

  public getMetricsDataByStage = (metrics: DashboardDataItemWithStage[]) => {
    const { year, month } = getLastClosedMonth(this.endDate);

    const lastMonthData = this.getDateFormMonthData(metrics)
      .filter((d) => d.year === year && d.month === month)
      .sort((a, b) => a.value - b.value) as DashboardDataItemWithStage[];

    if (!lastMonthData.length) {
      return {
        data: [],
        keys: ['value'],
        indexBy: 'stage',
        date: formatDate(`${year}-${month}`),
        withSign: false,
      };
    }

    const data = lastMonthData.map((d) => ({
      date: this.processDataItemWithDate(d),
      value: d.value,
      stage: d.stage,
    }));

    return {
      data,
      keys: ['value'],
      indexBy: 'stage',
      date: formatDate(`${year}-${month}`),
      withSign: false,
    };
  };

  public getMetricsDataByStageForFunnelChart = (
    metrics: DashboardDataItemWithStage[]
  ) => {
    const { year, month } = getLastClosedMonth(this.endDate);

    const lastMonthData = this.getDateFormMonthData(metrics)
      .filter((d) => d.year === year && d.month === month)
      .sort((a, b) => b.value - a.value) as DashboardDataItemWithStage[];

    if (!lastMonthData.length) {
      return {
        data: [],
        date: formatDate(`${year}-${month}`),
      };
    }

    const data = lastMonthData.map((d, id) => ({
      id: `stage_${id}`,
      value: d.value,
      label: d.stage,
    }));

    return {
      data,
      date: formatDate(`${year}-${month}`),
    };
  };

  public getSalesCycleByStageData = () => {
    return this.getMetricsDataByStage(
      this.dashboardData.averageDaysByStageMetrics
    );
  };

  public getSalesFunnelDealsNumber = () => {
    return this.getMetricsDataByStageForFunnelChart(
      this.dashboardData.customersByStagesMetrics
    );
  };

  public getDealValueByStageData = () => {
    return this.getMetricsDataByStageForFunnelChart(
      this.dashboardData.revenueByStagesMetrics
    );
  };

  public getConversionRatesByStageData = () => {
    return this.getMetricsDataByStageForFunnelChart(
      this.dashboardData.conversionByStageMetrics
    );
  };

  private prepareTableData(
    actualKey: keyof SalesMarketingDashboardData,
    budgetedKey?: keyof SalesMarketingDashboardData
  ) {
    const actualTableDataItems = this.dashboardData[
      actualKey
    ] as DashboardDataItemWithCategory[];
    const budgetedTableDataItems = budgetedKey
      ? (this.dashboardData[budgetedKey] as DashboardDataItemWithCategory[])
      : [];

    const actualMonthTableData = this.getDateFormMonthData(
      actualTableDataItems
    ) as DashboardDataItemWithCategory[];
    const budgetedMonthTableData = budgetedKey
      ? (this.getDateFormMonthData(
          budgetedTableDataItems
        ) as DashboardDataItemWithCategory[])
      : [];

    const { year, month } = getLastClosedMonth(this.endDate);

    const actualLastMonthDataSet = actualMonthTableData.filter(
      (item) => item.year === year && item.month === month
    );
    const budgetedLastMonthDataSet = budgetedKey
      ? budgetedMonthTableData.filter(
          (item) => item.year === year && item.month === month
        )
      : [];

    actualLastMonthDataSet.sort((a, b) => a.value - b.value);

    const totalActualCurrentMonth = actualLastMonthDataSet.reduce(
      (acc, item) => {
        return acc + item.value;
      },
      0
    );

    const totalBudgetedCurrentMonth = budgetedLastMonthDataSet.reduce(
      (acc, item) => {
        return acc + item.value;
      },
      0
    );

    const result = actualLastMonthDataSet.map((actualItem) => {
      const budgetedItem: DashboardDataItemWithCategory | undefined =
        budgetedKey
          ? budgetedLastMonthDataSet.find(
              (budgeted) => budgeted.category === actualItem.category
            )
          : undefined;

      const formattedActual = new DashboardValueBuilderv2(actualItem.value)
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();
      const formattedBudgeted = new DashboardValueBuilderv2(
        budgetedItem?.value || 0
      )
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();

      return {
        category: actualItem.category,
        current: {
          type: 'ACTUAL',
          value: formattedActual,
        },
        previous: {
          type: 'BUDGET',
          value: formattedBudgeted,
        },
        change: calculatePercentageChange(
          actualItem.value,
          budgetedItem?.value || 0
        ),
      };
    });

    return {
      rows: result,
      totalCurrentMonth: new DashboardValueBuilderv2(totalActualCurrentMonth)
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue(),
      totalPreviousMonth: new DashboardValueBuilderv2(totalBudgetedCurrentMonth)
        .reverseSignIfNegative()
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue(),
      currentHeader: 'actual',
      previousHeader: 'budget',
    };
  }

  public getExpensesBreakdownActualProjected = () => {
    const { totalExpenseBySalesMarketingCategoryMetrics } = this.dashboardData;

    const filteredRevenueByCategoriesMetrics = this.getDateFormMonthData(
      totalExpenseBySalesMarketingCategoryMetrics
    ) as DashboardDataItemWithCategory[];

    const dateRange = getLongestDateRange([filteredRevenueByCategoriesMetrics]);

    const formattedCategoryRevenueByDate = [];

    let current = dateRange.start;

    while (current <= dateRange.end) {
      const year = current.year;
      const month = current.month;

      const revenueForCurrentMonth = filteredRevenueByCategoriesMetrics.filter(
        (item: DashboardDataItemWithCategory) =>
          item.year === year && item.month === month
      ) as DashboardDataItemWithCategory[];

      const formattedEntry: { [key: string]: any } = {
        date: `${year}-${month}`,
      };

      revenueForCurrentMonth.forEach((item) => {
        formattedEntry[item.category] = parseFloat(item.value.toFixed(2));
      });

      formattedCategoryRevenueByDate.push(formattedEntry);

      current = current?.plus({ months: 1 });
    }

    const allCategories = Array.from(
      new Set(
        filteredRevenueByCategoriesMetrics.map(
          (item: DashboardDataItemWithCategory) => item.category
        )
      )
    );

    return {
      keys: allCategories,
      indexBy: 'date',
      data: formattedCategoryRevenueByDate,
    };
  };

  private getSummaryData(
    type: SalesMarketingDashboardDataType,
    signType: 'percent' | 'value' = 'value'
  ): SummaryDataset | null {
    const map = new Map<
      SalesMarketingDashboardDataType,
      keyof SalesMarketingDashboardData
    >([
      [
        SalesMarketingDashboardDataType.AVG_REVENUE_GROWTH,
        'revenueGrowthActualMetrics',
      ],
      [
        SalesMarketingDashboardDataType.AVG_EXPENSES_GROWTH,
        'acquistionCostsGrowthMetrics',
      ],
      [SalesMarketingDashboardDataType.MQL_SUMMARY, 'mqlCustomersMetrics'],
      [SalesMarketingDashboardDataType.PER_MQL_SUMMARY, 'costPerMqlMetrics'],
      [SalesMarketingDashboardDataType.SQL_SUMMARY, 'sqlCustomersMetrics'],
      [SalesMarketingDashboardDataType.PER_SQL_SUMMARY, 'costPerSqlMetrics'],
      [
        SalesMarketingDashboardDataType.CONVERSION_MQL_SQL_SUMMARY,
        'mqlSqlAccumulatedConversionRateMetrics',
      ],
      [
        SalesMarketingDashboardDataType.NEW_REVENUE_SUMMARY,
        'newRevenueActualMetrics',
      ],
      [
        SalesMarketingDashboardDataType.WON_DEALS_SUMMARY,
        'newWonCustomersMetrics',
      ],
      [
        SalesMarketingDashboardDataType.AVG_DEAL_VALUE_SUMMARY,
        'averageNewWonDealValueMetrics',
      ],
      [
        SalesMarketingDashboardDataType.SOURCES_NEW_REVENUE_SUMMARY,
        'newRevenueActualMetrics',
      ],
      [
        SalesMarketingDashboardDataType.CONVERSION_MQL_WON_SUMMARY,
        'mqlWonAccumulatedConversionRateMetrics',
      ],
      [SalesMarketingDashboardDataType.ROI_SUMMARY, 'roiMetrics'],
    ]);

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const result = this.getHeaderData(
      map.get(type) as keyof SalesMarketingDashboardData
    );

    return {
      value: result.monthData.value ?? 0,
      prevMonthData: result.prevMonthData.value ?? 0,
      year: result?.year ?? 0,
      month: result?.month ?? 0,
      signType,
    };
  }
}
