import React, { useState } from 'react';
import './update-account.style.css';
import { Edit3, SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';
import { useLocation, useNavigate } from 'react-router-dom';

interface UpdateAccountModalProps {
  onClose: () => void;
  onSave: (
    id: number,
    accountName: string,
    accountGroup: number,
    cacCalculation?: boolean,
    intercompanyAccount?: boolean
  ) => void;
  account: {
    id: number;
    accountName: string;
    accountGroup?: number;
    cacCalculation?: boolean;
    intercompanyAccount?: boolean;
  } | null;
  accountTypeOptions: { id: number; name: string }[];
}

const UpdateAccountModal: React.FC<UpdateAccountModalProps> = ({
  onClose,
  onSave,
  account,
  accountTypeOptions,
}) => {
  const [accountName, setAccountName] = useState(account?.accountName || '');
  const [accountGroup, setAccountGroup] = useState(account?.accountGroup);
  const [cacCalculation, setCacCalculation] = useState(
    account?.cacCalculation || false
  );
  const [intercompanyAccount, setIntercompanyAccount] = useState(
    account?.intercompanyAccount || false
  );
  const [emptyNameError, setEmptyNameError] = useState<string | null>(null);
  const [emptyGroupError, setEmptyGroupError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToGroupsPage = () => {
    const match = location.pathname.match(/\/companies\/(\d+)\//);
    const companyId = match ? match[1] : null;

    if (companyId)
      navigate(`/manager/companies/${companyId}/mapping/fin-groups`);
  };

  const handleSaveClick = () => {
    if (!accountName.trim()) {
      setEmptyNameError('Account name is required');
      return;
    }

    if (!accountGroup) {
      setEmptyGroupError('Group is required');
      return;
    }

    if (account) {
      onSave(
        account.id,
        accountName,
        Number(accountGroup),
        cacCalculation,
        intercompanyAccount
      );
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="update-account-modal">
        <div className="update-account-modal__header">
          <h2>Update Account</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="update-account-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Account name"
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setEmptyNameError(null);
              }}
              name="accountName"
            />
            {emptyNameError && (
              <span className="error-message">{emptyNameError}</span>
            )}
          </div>
          <div>
            <select
              id="account-group"
              onChange={(e) => {
                setAccountGroup(Number(e.target.value));
                setEmptyGroupError(null);
              }}
              name="accountGroup"
              className="select-input"
              value={accountGroup}
            >
              {accountTypeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {emptyGroupError && (
              <span className="error-message">{emptyGroupError}</span>
            )}
            <button
              className="manage-groups-button"
              onClick={() => navigateToGroupsPage()}
            >
              Manage Groups
              <Edit3 className="manage-groups-button" size={14} />
            </button>
          </div>
          <div className="input-group checkbox-group">
            <input
              id="cac-calculation"
              type="checkbox"
              checked={cacCalculation}
              onChange={(e) => setCacCalculation(e.target.checked)}
              name="cacCalculation"
            />
            <label htmlFor="cac-calculation">CAC Calculation</label>
            <input
              id="intercompany-account"
              type="checkbox"
              checked={intercompanyAccount}
              onChange={(e) => setIntercompanyAccount(e.target.checked)}
              name="intercompanyAccount"
            />
            <label htmlFor="intercompany-account">Intercompany account</label>
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleSaveClick}
        >
          Save
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default UpdateAccountModal;
