import Heatmap from '../../../../pages/dashboard/components/cards-content/heatmap.component';
import { DashboardCard, withWrap } from './dashboard-card';

export class HeatmapChartCard extends DashboardCard<any, any> {
  constructor(identifier: string, layout: any, data: any, options: any) {
    super(identifier, layout);

    this.component = withWrap(
      <>
        <p>{data.name}</p>
        <Heatmap
          data={data.dataset.data}
          colorScale={options.colors}
          products={data.dataset.labels.products}
        />
      </>
    );
  }
}
