import { LineChart } from '../../../../pages/dashboard/components/cards-content/line-chart.component';
import {
  DashboardCard,
  NoDataAvailableComonent,
  withWrap,
} from './dashboard-card';

export class LineChartCard extends DashboardCard<
  {
    dataset: any;
    curve?:
      | 'linear'
      | 'basis'
      | 'cardinal'
      | 'catmullRom'
      | 'monotoneX'
      | 'monotoneY'
      | 'natural'
      | 'step'
      | 'stepAfter'
      | 'stepBefore';
    enablePoints?: boolean;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: any;
      curve?:
        | 'linear'
        | 'basis'
        | 'cardinal'
        | 'catmullRom'
        | 'monotoneX'
        | 'monotoneY'
        | 'natural'
        | 'step'
        | 'stepAfter'
        | 'stepBefore';
      enablePoints?: boolean;
    },
    options?: any
  ) {
    super(identifier, layout);
    if (!data.dataset || !data.dataset.dataset.length) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }
    this.component = withWrap(
      <>
        {data.name}
        <LineChart
          options={options}
          data={data.dataset}
          name={data.name}
          identifier={identifier}
        />
      </>
    );
  }
}
