import { ResponsiveStream } from '@nivo/stream';
import {
  formatDate,
  reformatToThousandsWithoutSign,
} from '../../../../utils/dashboard/formatters';

interface AreaChartProps {
  data: any;
  options?: any;
}

const getMaxValue = (slices: any) => {
  let maxValue = 0;

  slices.forEach((slice: any) => {
    slice.stack.forEach((point: any) => {
      const absValue = Math.abs(point.value);
      if (absValue > maxValue) {
        maxValue = absValue;
      }
    });
  });

  return maxValue;
};

const CustomLabelLayer = ({ slices }: any) => {
  const maxValue = getMaxValue(slices);

  return (
    <>
      {slices.map((slice: any) =>
        slice.stack.map((point: any, index: number) => {
          const currentValue = point.value;
          const percentage = (Math.abs(currentValue) / maxValue) * 100;
          let yOffset = 0;

          if (currentValue > 0) {
            yOffset = -percentage;
          } else {
            yOffset = 0;
          }

          return (
            <g
              key={index}
              transform={`translate(${point.x}, ${point.y1 + yOffset})`}
            >
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 12,
                  strokeWidth: 3,
                  paintOrder: 'stroke',
                  pointerEvents: 'none',
                }}
              >
                {reformatToThousandsWithoutSign(point.value)}
              </text>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 12,
                  fill: 'black',
                  pointerEvents: 'none',
                }}
              >
                {reformatToThousandsWithoutSign(point.value)}
              </text>
            </g>
          );
        })
      )}
    </>
  );
};

const AreaChart = (properties: AreaChartProps) => (
  <div
    className={'drag-cancel'}
    style={{
      width: '100%',
      height: '100%',
    }}
  >
    <ResponsiveStream
      data={properties.data.data}
      valueFormat={(value: number) =>
        properties.options?.percents
          ? `${value}%`
          : reformatToThousandsWithoutSign(value).toString()
      }
      keys={properties.data.keys}
      margin={{ top: 50, right: 50, bottom: 50, left: 30 }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
        format: (index: number) => formatDate(properties.data.data[index].date),
      }}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      offsetType="diverging"
      fillOpacity={0.7}
      curve={properties.options?.curve || 'basis'}
      borderColor={{ theme: 'background' }}
      colors={properties.options?.colors || { scheme: 'default' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#2c998f',
          size: 4,
          padding: 2,
          stagger: true,
        },
        {
          id: 'squares',
          type: 'patternSquares',
          background: 'inherit',
          color: '#e4c912',
          size: 6,
          padding: 2,
          stagger: true,
        },
      ]}
      legends={[
        {
          anchor: 'top-right',
          direction: 'row',
          justify: false,
          translateX: 30,
          translateY: -50,
          itemsSpacing: 80,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'right-to-left',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      layers={[
        'grid',
        'axes',
        'layers',
        'dots',
        'slices',
        CustomLabelLayer,
        'legends',
      ]}
    />
  </div>
);

export default AreaChart;
