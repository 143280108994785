import { useContext } from 'react';
import { RoleContext } from '../../../context/role.context';
import {
  cashflowDashboardSettings,
  profitAndLossDashboardSettings,
  revenueAnalysisDashboardSettings,
  salesMarketingDashboardSettings,
  snapshotDashboardSettings,
} from './dashboard.settings';
import { useParams } from 'react-router-dom';
import { DashboardCard } from './dashboard-cards/dashboard-card';
import { instanceWithToken } from '../../../tools/api';

const wrapBackendObject = (object: any) => {
  return {
    type: object.type,
    name: object.name,
    identifier: object.identifier,
    layout: {
      width: object.width,
      height: object.height,
      xPosition: object.xPositon,
      yPosition: object.yPositon,
    },
    dashboardType: object.dashboardType,
  };
};

const getDashboardStructureForManager = async (
  companyId: string | number | undefined,
  tabName: string
) => {
  const getData = async () => {
    const result = await instanceWithToken.get(
      `manager/dashboard/${companyId}?tab=${tabName}`
    );
    return result.data;
  };

  const result = await getData();

  if (result === null || result.length === 0) {
    return snapshotDashboardSettings;
  }
  const wLayout = result.map(wrapBackendObject);
  return wLayout;
};

const getDashboardStructureForClient = async (tabName: string) => {
  const getData = async () => {
    const result = await instanceWithToken.get(
      `api/me/dashboard-structure?tab=${tabName}`
    );
    return result.data;
  };

  const result = await getData();
  if (result === null || result.length === 0) {
    return snapshotDashboardSettings;
  }
  const wLayout = result.map(wrapBackendObject);
  return wLayout;
};

export const useDashboardStructure = (tabName: string) => {
  const role = useContext(RoleContext);
  const { companyId } = useParams();

  let structure: any[] = [];
  const getDashboardStructure = async () => {
    if (role.userType === 'MANAGER') {
      structure = await getDashboardStructureForManager(companyId, tabName);
    }

    if (role.userType === 'HUBSPOT') {
      structure = await getDashboardStructureForClient(tabName);
    }

    let localSettings;
    switch (tabName) {
      case 'SNAPSHOT':
        localSettings = snapshotDashboardSettings;
        break;
      case 'CASHFLOW':
        localSettings = cashflowDashboardSettings;
        break;
      case 'PL':
        localSettings = profitAndLossDashboardSettings;
        break;
      case 'REVENUE_ANALYSIS':
        localSettings = revenueAnalysisDashboardSettings;
        break;
      case 'SALES_MARKETING':
        localSettings = salesMarketingDashboardSettings;
        break;
      default:
        localSettings = snapshotDashboardSettings;
    }

    //todo temparoray fix, to discuss if must be saved in db or in card settings
    const defaultOptionsWrappedWithCard = localSettings.map(
      (defaultCardOption) => {
        const card = structure.find(
          (card) => card.identifier === defaultCardOption.identifier
        );

        if (card) {
          return {
            ...card,
            options: defaultCardOption.options,
          };
        }
        return defaultCardOption;
      }
    );
    return defaultOptionsWrappedWithCard;
  };

  const saveDashboardStructure = async (
    structure: any,
    dashboardTab: string
  ) => {
    const prepareObject = (dashboardCard: DashboardCard<unknown, unknown>) => {
      const object = {
        identifier: dashboardCard.i,
        xPosition: dashboardCard.x,
        yPosition: dashboardCard.y,
        width: dashboardCard.w,
        height: dashboardCard.h,
        dashboardType: dashboardCard.dashboardType,
        type: dashboardCard.type,
        name: dashboardCard.name,
        dashboardTab,
      };
      return object;
    };

    const objectToSave: Array<{
      identifier: string;
      xPosition: number;
      yPosition: number;
      width: number;
      height: number;
      dashboardType: string | undefined;
      type: string | undefined;
      name: string | undefined;
      dashboardTab: string;
    }> = [];

    for (const card of structure) {
      objectToSave.push(prepareObject(card));
    }
    if (role.userType === 'HUBSPOT') {
      await instanceWithToken.post('api/me/dashboard-structure', objectToSave);
    }
    if (role.userType === 'MANAGER') {
      await instanceWithToken.post(
        `manager/dashboard/${companyId}`,
        objectToSave
      );
    }
  };

  return { getDashboardStructure, saveDashboardStructure };
};
