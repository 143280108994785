export const NoDataAvailableComonent = ({ name }: { name: string }) => {
  return (
    <div className="no-data-component">
      <p>{name}</p>
      <p>No data provided for the reporting month</p>
    </div>
  );
};

export const withWrap = (Component: any) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: '16px',
      }}
    >
      {Component}
    </div>
  );
};

export abstract class DashboardCard<T, K> {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minH: number;

  name?: string;
  type?: string;
  dashboardType?: string;

  component: JSX.Element | null = (<div>Card without implementation </div>);

  constructor(
    identifier: string,
    layout: {
      width: number;
      xPosition: number;
      yPosition: number;
      height: number;
      minH?: number;
    },
    data?: T,
    options?: K
  ) {
    this.i = identifier;
    this.x = layout.xPosition || 0;
    this.y = layout.yPosition || 0;
    this.w = layout.width || 1;
    this.h = layout.height || 1;
    this.minH = layout.minH || 1;
  }

  public setProperties(properties: any) {
    this.name = properties.name;
    this.dashboardType = properties.dashboardType;
    this.type = properties.type;
  }
}
