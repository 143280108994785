import { BalanceSummary } from '../../../../pages/dashboard/components/cards-content/balance-summary.component';
import { Summary } from '../../../../pages/dashboard/components/cards-content/summary.component';
import {
  DashboardCard,
  NoDataAvailableComonent,
  withWrap,
} from './dashboard-card';
import { SplitSummary } from '../../../../pages/dashboard/components/cards-content/split-summary.component';
import { ComparisonSummary } from '../../../../pages/dashboard/components/cards-content/comparison-summary.component';

export class SummaryCard extends DashboardCard<
  {
    name: string;
    value: number;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: {
        value: number;
        prevMonthData: number;
        year: number;
        month: number;
        signType: 'percent' | 'value';
      };
    }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <Summary
        name={data.name}
        value={data.dataset.value}
        prevValue={data.dataset.prevMonthData}
        year={data.dataset.year}
        month={data.dataset.month}
        signType={data.dataset.signType}
      />
    );
  }
}

export class ComparisonSummaryCard extends DashboardCard<
  {
    name: string;
    value: number;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      dataset: {
        firstValue: number;
        secondValue: number;
        year: number;
        month: number;
      };
    },
    options: { firstName: string; secondName: string }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={options.firstName} />;
      return;
    }

    this.component = withWrap(
      <ComparisonSummary
        firstName={options.firstName}
        secondName={options.secondName}
        firstValue={data.dataset.firstValue}
        secondValue={data.dataset.secondValue}
        year={data.dataset.year}
        month={data.dataset.month}
      />
    );
  }
}

export class SplitSummaryCard extends DashboardCard<
  {
    name: string;
    value: number | string;
    subValues?: { title: string; value: number | string }[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: {
        value: number | string;
        subValues?: { title: string; value: number | string }[];
      };
    },
    options?: { color: string }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    const { name } = data;
    const { value, subValues } = data.dataset;

    this.component = withWrap(
      <SplitSummary
        name={name}
        value={value}
        subValues={subValues}
        options={options}
      />
    );
  }
}

export class BalanceSummaryCard extends DashboardCard<
  {
    name: string;
    value: number | string;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: {
      name: string;
      dataset: {
        value: number | string;
      };
    }
  ) {
    super(identifier, { minH: 2, ...layout });

    if (!data.dataset || !Object.keys(data.dataset).length) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <BalanceSummary name={data.name} value={data.dataset.value} />
    );
  }
}
