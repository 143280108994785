import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DashboardCard } from './dashboard-card';
import { reformatToThousandsWithoutSign } from '../../../../utils/dashboard/formatters';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    const isTotal = data.name.includes('Total');
    const valueType = data.uv > 0 ? 'Increase' : 'Decrease';

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <p>
          <strong>{data.name}</strong>
        </p>
        <p>
          {isTotal ? 'Total' : valueType}: {data.uv}
        </p>
        <p>Previous Value (pv): {data.pv}</p>
      </div>
    );
  }

  return null;
};
export class WaterfallCard extends DashboardCard<any, any> {
  constructor(identifier: string, layout: any, data: any, options: any) {
    const colorMapping: Record<string, string> = {
      Revenue: '#2BBC62',
      Cogs: '#864DFE',
      'Gross profit': '#EC48629C',
      Opex: '#1AA3E1',
      Ebitda: '#EC4862BF',
      'Other income': '#00A743',
      'Other expenses': '1AA3E11A',
      'Net profit': '#EC4862',
    };

    super(identifier, layout);
    this.component = (
      <div
        className={'drag-cancel'}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '',
          padding: '16px',
          boxSizing: 'border-box',
        }}
      >
        <p>{data.name}</p>
        <ResponsiveContainer>
          <BarChart
            layout={'vertical'}
            margin={{ bottom: 20, left: 100, right: 70 }}
            data={data.dataset.data}
          >
            <XAxis type="number" hide={true} />
            <YAxis
              type="category"
              dataKey="name"
              hide={false}
              tickMargin={60}
              tickSize={0}
              tickLine={false}
              axisLine={false}
              fontSize={14}
              width={80}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="pv" stackId="a" fill="#000000" fillOpacity={0} />
            <Bar dataKey="uv" stackId="a">
              <LabelList
                dataKey="uv"
                position="right"
                formatter={(value: number) =>
                  value >= 0 ? reformatToThousandsWithoutSign(value) : ''
                }
                fontSize={15}
              />
              <LabelList
                dataKey="uv"
                position="left"
                formatter={(value: number) =>
                  value < 0 ? reformatToThousandsWithoutSign(value) : ''
                }
                fontSize={15}
              />
              {data.dataset.data.map(
                (item: { name: string }, index: number) => (
                  <Cell
                    key={index}
                    fill={colorMapping[item.name] || '#17c0bf'}
                  />
                )
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
