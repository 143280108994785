import { ResponsiveFunnel } from '@nivo/funnel';
import { RaceChartDataset } from '../../../../hooks/client/dashboard/interfaces/dashboard-card.type';
import { reformatToThousandsWithoutSign } from '../../../../utils/dashboard/formatters';

export const FunnelChart = ({ dataset }: { dataset: RaceChartDataset }) => {
  const { data } = dataset;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
        interpolation="linear"
        shapeBlending={0}
        valueFormat={(value: number) =>
          reformatToThousandsWithoutSign(value).toString()
        }
        colors={{ scheme: 'blues' }}
        borderColor={{ theme: 'background' }}
        borderWidth={20}
        labelColor={{
          from: 'color',
          modifiers: [['darker', 3]],
        }}
        enableBeforeSeparators={false}
        enableAfterSeparators={false}
        currentPartSizeExtension={10}
        currentBorderWidth={20}
      />
    </div>
  );
};
