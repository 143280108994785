import AreaChart from '../../../../pages/dashboard/components/cards-content/area-chart.component';
import {
  DashboardCard,
  NoDataAvailableComonent,
  withWrap,
} from './dashboard-card';

export class AreaChartCard extends DashboardCard<
  {
    dataset: any;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: any },
    options?: any
  ) {
    super(identifier, layout);
    if (data.dataset?.data?.length === 0) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }
    this.component = withWrap(
      <>
        {data.name}
        <AreaChart options={options} data={data.dataset} />
      </>
    );
  }
}
