import {
  formatDate,
  DashboardValueBuilderv2,
  DiagramStringValueFormatBuilder,
} from '../../../../utils/dashboard/formatters';

const defaultTooltipStyle = (color: string) => ({
  padding: '5px 10px',
  color,
  background: '#fff',
  border: `1px solid grey`,
});

export const DefaultTooltipBody = ({ children, color }: any) => {
  return <div style={defaultTooltipStyle(color)}>{children}</div>;
};

export const DefaultHistoTooltip = ({ id, value, indexValue, color }: any) => {
  const formattedValue = new DashboardValueBuilderv2(value)
    .formatValue(1)
    .shouldIncludeLetter(true)
    .getValue();
  const formattedDate = formatDate(indexValue?.toString());
  return (
    <div style={defaultTooltipStyle(color)}>
      <p>{id}</p>
      <p>{formattedDate}</p>
      <p>{formattedValue}</p>
    </div>
  );
};

export const defaultTooltip = ({ id, value, indexValue, color }: any) => {
  return (
    <div
      style={{
        padding: '5px 10px',
        color,
        background: '#fff',
        border: `1px solid grey`,
      }}
    >
      <p>{id}</p>
      <p>{formatDate(indexValue?.toString())}</p>
      <p>
        {new DiagramStringValueFormatBuilder(value)
          .toThousands()
          .toThousands()
          .includeK()
          .getValue()}
      </p>
    </div>
  );
};
