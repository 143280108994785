import { DateTime } from 'luxon';

export const reformatToThousandsWithSigns = (value: number) => {
  let formattedValue = (value / 1000).toFixed(1);

  formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `$ ${formattedValue}K`;
};

export class DashboardValueBuilderv2 {
  private reformattedValue: string | number | null = null;
  private includeLetter = false;
  private includeSign = false;
  private suffix = '';

  constructor(private readonly value: number) {}

  shouldIncludeLetter(include: boolean) {
    this.includeLetter = include;
    return this;
  }

  shouldIncludeSign(include: boolean) {
    this.includeSign = include;
    return this;
  }

  formatValue(withFixed = 0) {
    let val = this.reformattedValue
      ? (this.reformattedValue as number)
      : this.value || 0;

    if (val >= 500_000 || val <= -500_000) {
      val = val / 1_000_000;
      this.suffix = 'M';
    } else if (val >= 1_000 || val <= -1_000) {
      val = val / 1_000;
      this.suffix = 'K';
    }

    this.reformattedValue = val
      .toFixed(withFixed)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return this;
  }

  includeDollarSign() {
    this.reformattedValue = `$ ${this.reformattedValue}`;
    return this;
  }

  reverseSignIfNegative() {
    this.reformattedValue =
      this.value < 0 ? this.value * -1 : this.reformattedValue;
    return this;
  }

  getValue(): string {
    return (
      (!this.includeSign ? '' : this.value > 0 ? '+' : '-') +
      this.reformattedValue +
      (this.includeLetter ? this.suffix : '')
    );
  }
}

export class DiagramStringValueFormatBuilder {
  private reformattedValue: string | number | null = null;

  constructor(private readonly value: number) {}

  toThousands(withFixed = 0) {
    const val = (this.value / 1000).toFixed(withFixed);
    this.reformattedValue = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return this;
  }

  includeDollarSign() {
    this.reformattedValue = `$ ${this.reformattedValue}`;
    return this;
  }

  includeK() {
    this.reformattedValue = `${this.reformattedValue}K`;
    return this;
  }

  getValue(): string {
    return this.reformattedValue?.toString() || '';
  }
}

export const reformatToThousands = (value: number, withSign = false) => {
  if (value >= 1000 || value <= -1000) {
    return reformatToThousandsWithSigns(value);
  }
  return withSign ? '$ ' + value : value;
};

export const reformatToThousandsWithoutSign = (value: number) => {
  if (value >= 1000 || value <= -1000) {
    let formattedValue = (value / 1000).toFixed(1);

    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${formattedValue}K`;
  }
  return value;
};

export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  const [year, month] = dateString.split('-').map(Number);
  if (year && month && month >= 1 && month <= 12) {
    const date = DateTime.fromObject({ year, month });
    return date.toFormat('MMM-yy');
  }

  return dateString;
};

export const formatDateV2 = (dateString: string): string => {
  if (!dateString) {
    return '';
  }
  const [year, month] = dateString.split('-').map(Number);
  const date = DateTime.fromObject({ year, month });
  return date.toFormat('MMM yy');
};

export const reverseSign = (value: number | undefined) => {
  if (!value) {
    return 0;
  }

  if (value > 0) {
    return value;
  }

  return value * -1;
};

export const reformatArrayToPercents = (
  values: any,
  isPercentsMode = false
) => {
  if (isPercentsMode) {
    return values.map((value: any) => {
      return {
        ...value,
        value: Number(value.value * 100).toFixed(2),
      };
    });
  }
  return values;
};

export const reformatToPercents = (value: number): string => {
  return Number(value * 100).toFixed(2);
};

export const convertToDateRange = (monthYear: string) => {
  const [year, month] = monthYear.split('-').map(Number);

  const startDate = new Date(Date.UTC(year, month - 1, 1, 12, 0, 0));
  const endDate = new Date(Date.UTC(year, month, 0, 12, 0, 0));

  return { startDate, endDate };
};
