import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagerHttpMappingService } from '../../tools/manager-api/manager-http-mapping';
import {
  AccountGroup,
  IMappingPagination,
  Mapping,
  MappingMasterList,
  MappingSource,
  SourceType,
} from '../../tools/manager-api/types/mapping';
import { ToasterContext } from '../../context/toaster.context';

interface IAccountTypeWithMasterLists {
  id: number;
  name: string;
  cf_activity: string;
  reports: string;
  debit_credit: string;
  masterLists: MappingMasterList[];
  pagination: {
    page: number;
    pages: number;
    total: number;
  };
  order: 'asc' | 'desc';
}

export const useMapping = () => {
  const { companyId } = useParams();
  const ctx = useContext(ToasterContext);
  const [fuelRecommendations, setFuelRecommendations] = useState<
    MappingMasterList[]
  >([]);
  const [integrations, setIntegrations] = useState<MappingSource[] | null>(
    null
  );
  const [integrationMappings, setIntegrationMappings] = useState<
    Mapping[] | null
  >(null);
  const [groupedMasterLists, setGroupedMasterLists] = useState<
    IAccountTypeWithMasterLists[]
  >([]);
  const [allMasterListData, setAllMasterListData] = useState<
    MappingMasterList[]
  >([]);
  const [allAccountsGroupsData, setAllAccountGroups] = useState<AccountGroup[]>(
    []
  );
  const [totalMasterCount, setTotalMasterCount] = useState(0);
  const [totalMappingCount, setTotalMappingCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [chooseBaseModalActive, setChooseBaseModalActive] =
    useState<boolean>(false);
  const [chooseBaseModalShowed, setChooseBaseModalShowed] =
    useState<boolean>(false);
  const fetchIntegrationsAndMasterLists = useCallback(
    async (sourceType: SourceType, pagination?: IMappingPagination) => {
      setLoading(true);
      try {
        const { data: integrationsData } =
          await ManagerHttpMappingService.getAvailableIntegrations(
            Number(companyId),
            sourceType
          );

        const {
          data: { result: masterLists, total },
        } = await ManagerHttpMappingService.filterMasterList(
          Number(companyId),
          sourceType,
          {
            page: pagination?.page,
            limit: pagination?.limit,
            search: pagination?.search,
            sorting: pagination?.sorting,
          }
        );

        setIntegrations(integrationsData);
        setFuelRecommendations(masterLists);
        setTotalMasterCount(total);
        fetchAllMasterListData(sourceType);
        if (
          !chooseBaseModalShowed &&
          !masterLists.length &&
          !allMasterListData.length
        ) {
          setChooseBaseModalActive(true);
          setChooseBaseModalShowed(true);
        }
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list and integrations',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [companyId, ctx, chooseBaseModalShowed, allMasterListData.length]
  );
  const fetchMappings = useCallback(
    async (
      sourceType: SourceType,
      activeIntegration: number | null,
      pagination: IMappingPagination
    ) => {
      if (activeIntegration === null) return;
      setLoading(true);
      try {
        const {
          data: { result: mappings, total },
        } = await ManagerHttpMappingService.getMappingList(
          Number(companyId),
          sourceType,
          activeIntegration,
          {
            page: pagination.page,
            limit: pagination.limit,
            search: pagination.search,
            sorting: pagination.sorting,
          }
        );

        setIntegrationMappings(mappings);
        setTotalMappingCount(total);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch mappings',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [companyId, ctx]
  );

  const fetchGroupedMasterLists = useCallback(
    async (
      accountTypeId?: number,
      filterDto?: { page?: number; limit?: number; order?: 'asc' | 'desc' }
    ) => {
      try {
        const { data } = await ManagerHttpMappingService.getGroupedMasterLists(
          Number(companyId),
          accountTypeId,
          filterDto
        );

        setGroupedMasterLists((prev) => {
          if (accountTypeId) {
            return prev.map((accountType) =>
              accountType.id === accountTypeId
                ? { ...accountType, ...data }
                : accountType
            );
          }
          return data;
        });
      } catch (error) {
        ctx.updateToaster({
          content: 'Failed to fetch grouped master lists',
          isError: true,
        });
        console.error('Error fetching grouped master lists:', error);
      }
    },
    [companyId, ctx]
  );

  const toggleMasterSortingByAccountTypeId = useCallback(
    async (accountTypeId: number) => {
      const accountType = groupedMasterLists.find(
        (acc) => acc.id === accountTypeId
      );
      if (!accountType) return;

      const newOrder = accountType.order === 'asc' ? 'desc' : 'asc';

      try {
        const { data: groupedAccountType } =
          await ManagerHttpMappingService.getGroupedMasterLists(
            Number(companyId),
            accountTypeId,
            {
              page: accountType.pagination.page,
              limit: 10,
              order: newOrder,
            }
          );

        setGroupedMasterLists((prev) =>
          prev.map((item) =>
            item.id === accountTypeId
              ? {
                  ...item,
                  ...groupedAccountType,
                  order: newOrder,
                }
              : item
          )
        );
      } catch (error) {
        ctx.updateToaster({
          content: 'Failed to toggle sorting',
          isError: true,
        });
        console.error('Error toggling sorting:', error);
      }
    },
    [groupedMasterLists, companyId, ctx]
  );

  const fetchAllMasterListData = useCallback(
    async (sourceType: SourceType) => {
      try {
        const { data } = await ManagerHttpMappingService.getMasterList(
          Number(companyId),
          sourceType
        );
        setAllMasterListData(data);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list',
          isError: true,
        });
      }
    },
    [companyId, ctx]
  );

  const fetchAllAccountGroups = async () => {
    try {
      const { data } = await ManagerHttpMappingService.getAccountGroups(
        Number(companyId)
      );
      setAllAccountGroups(data);
    } catch {
      ctx.updateToaster({
        content: 'Failed to fetch master list',
        isError: true,
      });
    }
  };

  return {
    fuelRecommendations,
    integrations,
    integrationMappings,
    allMasterListData,
    totalMasterCount,
    totalMappingCount,
    loading,
    chooseBaseModalActive,
    groupedMasterLists,
    allAccountsGroupsData,
    fetchIntegrationsAndMasterLists,
    fetchMappings,
    fetchAllMasterListData,
    fetchGroupedMasterLists,
    fetchAllAccountGroups,
    toggleMasterSortingByAccountTypeId,
    setLoading,
    setChooseBaseModalActive,
    setIntegrationMappings,
    setGroupedMasterLists,
  };
};
