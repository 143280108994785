import { AxiosResponse } from 'axios';
import { instanceWithToken } from '../api';
import { AxiosData } from '../api-services/interfaces';
import { Provider } from '../api-services/types/connected-sources';

export class ManagerHttpDataSourceService {
  public static deleteQuickbooksIntegration(integrationId: number) {
    return instanceWithToken.delete(`manager/quickbooks/${integrationId}`);
  }

  public static deleteSalesIntegration(integrationId: number) {
    return instanceWithToken.delete(`manager/sales/${integrationId}`);
  }

  public static deleteCrmIntegration(integrationId: number) {
    return instanceWithToken.delete(`manager/crm/${integrationId}`);
  }

  public static importSalesIntegration(
    integrationId: number,
    provider: Provider
  ) {
    return instanceWithToken.post(
      `manager/import/sales/${integrationId}?provider=${provider}`
    );
  }

  public static importPayrollIntegration(integrationId: number) {
    return instanceWithToken.post(`manager/import/payroll/${integrationId}`);
  }

  public static deletePayrollIntegration(integrationId: number) {
    return instanceWithToken.delete(`manager/payroll/${integrationId}`);
  }

  public static importCrmIntegration(
    integrationId: number,
    provider: Provider
  ) {
    return instanceWithToken.post(
      `manager/import/crm/${integrationId}?provider=${provider}`
    );
  }

  public static importQuickbooksIntegration(integrationId: number) {
    return instanceWithToken.post(`manager/import/quickbooks/${integrationId}`);
  }

  public static connectGSheet(
    companyId: number,
    dataType: string,
    authCode: string,
    fileName: string,
    gsheetmeta: { spreadsheet_id: string; sheet_id: number }
  ): Promise<AxiosResponse<any, any>> {
    return instanceWithToken.post(`manager/companies/${companyId}/gsheet`, {
      authCode: authCode.toString(),
      dataType: dataType,
      fileName: fileName,
      gsheetmeta: gsheetmeta,
    });
  }

  public static deleteGSheetConnection(integrationId: number) {
    return instanceWithToken.delete(`manager/gsheet/${integrationId}`);
  }

  public static importGSheetConnection(
    integrationId: number,
    dataType: string
  ) {
    const typeMap: any = {
      Payroll: 'payroll',
      Financial: 'quickbooks',
      Sales: 'sales',
      Marketing: 'marketing',
    };

    return instanceWithToken.post(
      `manager/import/${typeMap[dataType]}/${integrationId}?gsheet=true`
    );
  }

  public static getSalesAuthUrl(
    companyId: number,
    provider: Provider
  ): Promise<
    AxiosData<{
      integrationId: number;
      authUri: string;
    }>
  > {
    return instanceWithToken.get(
      `manager/companies/${companyId}/sales/auth-uri?provider=${provider}`
    );
  }

  public static getCrmAuthUrl(
    companyId: number,
    provider: Provider
  ): Promise<
    AxiosData<{
      integrationId: number;
      authUri: string;
    }>
  > {
    return instanceWithToken.get(
      `manager/companies/${companyId}/crm/auth-uri?provider=${provider}`
    );
  }

  public static getQuickbooksAuthUrl(
    companyId: number
  ): Promise<AxiosData<{ authUri: string }>> {
    return instanceWithToken.get(
      `manager/companies/${companyId}/quickbooks/auth-uri`
    );
  }

  public static createPayrollIntegration(
    companyId: number,
    authCode: string,
    provider: string
  ): Promise<AxiosResponse<any, any>> {
    return instanceWithToken.post(`manager/companies/${companyId}/payroll`, {
      authCode: authCode.toString(),
      provider: provider,
    });
  }
}
