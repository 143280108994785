import { ResponsiveBar } from '@nivo/bar';
import {
  formatDate,
  reformatToPercents,
  DashboardValueBuilderv2,
  convertToDateRange,
} from '../../../../../utils/dashboard/formatters';
import { DefaultTooltipBody } from '../default-tooltip';
import { HistogramDataset } from '../../../../../hooks/client/dashboard/interfaces/dashboard-card.type';
import { DrilldownContext } from '../../../../../hooks/client/dashboard/drill-down/drill-down.context';
import { useContext } from 'react';

export const StackedBarChart = ({
  dataset,
  options,
  name,
  identifier,
}: {
  dataset: HistogramDataset;
  options?: { colors?: string[]; percents?: boolean };
  name: string;
  identifier: string;
}) => {
  const drillDownContext = useContext(DrilldownContext);
  if (!dataset || !dataset.data || dataset.data.length === 0) {
    return <div>No data availableNo data provided for the reporting month</div>;
  }

  const longestKey = dataset.keys.reduce((a, b) =>
    a.length > b.length ? a : b
  ).length;

  const formattedData = options?.percents
    ? dataset.data.map((d) => {
        return {
          ...d,
          profit: reformatToPercents(d.profit),
          cogs: reformatToPercents(d.cogs),
          opex: reformatToPercents(d.opex),
        };
      })
    : dataset.data;

  const CustomTotalLayer = ({ bars }: any) => {
    const barsByIndex = bars.reduce((acc: any, bar: any) => {
      if (!acc[bar.data.indexValue]) {
        acc[bar.data.indexValue] = [];
      }
      acc[bar.data.indexValue].push(bar);
      return acc;
    }, {});

    return Object.keys(barsByIndex).map((indexValue) => {
      const barStack = barsByIndex[indexValue];
      const topBar = barStack.reduce((prev: any, current: any) =>
        prev.y < current.y ? prev : current
      );

      const totalValue = dataset.keys.reduce(
        (sum, key) => sum + (topBar.data.data[key] || 0),
        0
      );

      const formattedTotalValue = options?.percents
        ? `${totalValue}%`
        : new DashboardValueBuilderv2(totalValue)
            .formatValue(1)
            .shouldIncludeLetter(true)
            .getValue();

      return (
        <text
          key={`${topBar.key}-total`}
          x={topBar.x + topBar.width / 2}
          y={topBar.y - 5}
          textAnchor="middle"
          fontSize="10px"
          fill="#000"
        >
          {formattedTotalValue}
        </text>
      );
    });
  };

  const handleClick = (event: any, bar: any) => {
    event.stopPropagation();

    const date = bar.indexValue;

    if (drillDownContext?.setData) {
      drillDownContext.setData({
        date: convertToDateRange(date),
        name: name || '',
        identifier: identifier || '',
      });
    }
  };

  return (
    <div className={'drag-cancel'} style={{ width: '100%', height: '100%' }}>
      <ResponsiveBar
        theme={{
          text: {
            fontSize: '10px',
            fontFamily: 'Karelia Web Regular',
          },
        }}
        data={formattedData}
        keys={dataset.keys}
        indexBy={dataset.indexBy}
        onClick={(bar, event) => handleClick(event, bar)}
        axisBottom={{
          tickSize: 0,
          format: (value: string) => formatDate(value),
        }}
        axisLeft={null}
        enableGridY={false}
        margin={{
          top: 20,
          right: 130 + 20 + longestKey * 2,
          bottom: 50,
          left: 0,
        }}
        padding={0.3}
        layout="vertical"
        colors={options?.colors || { scheme: 'greens' }}
        colorBy={'id'}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        tooltip={({ id, value, indexValue, color }) => {
          const valueToRender = options?.percents
            ? `${value}%`
            : new DashboardValueBuilderv2(value)
                .formatValue(1)
                .shouldIncludeLetter(true)
                .getValue();
          const dateValue = formatDate(indexValue?.toString());
          return (
            <DefaultTooltipBody color={color}>
              <p>
                <strong>Category:</strong> {id}
              </p>
              <p>
                <strong>Date:</strong> {dateValue}
              </p>
              <p>
                <strong>Value:</strong> {valueToRender}
              </p>
            </DefaultTooltipBody>
          );
        }}
        label={(d) => {
          return new DashboardValueBuilderv2(d.value || 0)
            .formatValue(1)
            .shouldIncludeLetter(true)
            .getValue();
        }}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 5,
            itemWidth: 100,
            itemHeight: 40,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        layers={[
          'grid',
          'axes',
          'bars',
          'markers',
          'legends',
          CustomTotalLayer,
        ]}
      />
    </div>
  );
};
