import { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  MappingMasterList,
  SourceType,
} from '../../../tools/manager-api/types/mapping';
import { ManagerHttpMappingService } from '../../../tools/manager-api/manager-http-mapping';
import { Edit3, Trash2, ArrowUp, ArrowDown } from 'lucide-react';
import AddAccountGroupModal from '../modals/add-account/add-account.modal';
import UpdateAccountGroupModal from '../modals/update-account/update-account.modal';
import './financial-account.style.css';
import arrowImg from '../../../../../public/images/arrow.png';
import { ToasterContext } from '../../../context/toaster.context';
import ChooseBaseModal from '../modals/choose-base/choose-base.modal';
import { Loader } from '../components/loader.component';
import { UpdatedButtonComponent } from '../../../shared-components/button/updated.button.component';
import { useMapping } from '../mapping.hook';
import { MappingPagination } from '../components/mapping-pagination.components';
import React from 'react';
import { Pagination } from '../components/pagination.component';
import { CustomSelectWithSearch } from '../components/custom-select-search.component';
import { SearchBar } from '../components/search-bar.component';
const PAGE_LIMIT = 50;
const SOURCE_TYPE: SourceType = 'ACCOUNT';

export const FinancialAccounts = () => {
  const { companyId } = useParams();
  const {
    integrations,
    loading,
    chooseBaseModalActive,
    integrationMappings,
    allMasterListData,
    totalMappingCount,
    groupedMasterLists,
    allAccountsGroupsData,
    fetchGroupedMasterLists,
    toggleMasterSortingByAccountTypeId,
    fetchMappings,
    fetchIntegrationsAndMasterLists,
    fetchAllAccountGroups,
    setLoading,
    setChooseBaseModalActive,
    setIntegrationMappings,
  } = useMapping();

  const [activeIntegration, setActiveIntegration] = useState<string | null>(
    null
  );
  const [addAccountModalActive, setAddAccountModalActive] =
    useState<boolean>(false);
  const [updateAccountModalActive, setUpdateAccountModalActive] =
    useState<boolean>(false);
  const [accountToUpdate, setAccountToUpdate] = useState<{
    id: number;
    accountName: string;
    accountGroup?: number;
    cacCalculation: boolean;
  } | null>(null);
  const [updatedMappings, setUpdatedMappings] = useState<
    { id: number; from: string; to: number | undefined }[]
  >([]);
  const [mappingSearch, setMappingSearch] = useState<string>('');
  const [mappingPage, setMappingPage] = useState(1);
  const [mappingSorting, setMappingSorting] = useState<{
    field: string;
    order: 'asc' | 'desc';
  }>({
    field: 'name',
    order: 'asc',
  });
  const [openedAccordions, setOpenedAccordions] = useState<number[]>([]);
  const ctx = useContext(ToasterContext);

  const fetchMappingData = useCallback(
    (searchTerm: string) => {
      fetchMappings(SOURCE_TYPE, Number(activeIntegration), {
        page: mappingPage,
        limit: PAGE_LIMIT,
        search: searchTerm,
        sorting: [{ key: mappingSorting.field, order: mappingSorting.order }],
      });
    },
    [
      activeIntegration,
      fetchMappings,
      mappingPage,
      mappingSorting.field,
      mappingSorting.order,
    ]
  );

  useEffect(() => {
    fetchGroupedMasterLists();
    fetchIntegrationsAndMasterLists(SOURCE_TYPE);
    fetchAllAccountGroups();
  }, [companyId]);

  const handleChangePageByAccountType = async (
    page: number,
    accountTypeId: number,
    order: 'asc' | 'desc'
  ): Promise<void> => {
    if (!accountTypeId || !companyId) return;

    try {
      await fetchGroupedMasterLists(accountTypeId, { page: page || 1, order });
    } catch (error) {
      ctx.updateToaster({
        content: `Failed to update page data for account type ${accountTypeId}`,
        isError: true,
      });
      console.error('Error changing page by account type:', error);
    }
  };

  const isAccordionOpen = (accountTypeId: number): boolean => {
    return openedAccordions.includes(accountTypeId);
  };

  const toggleAccordion = (accountTypeId: number): void => {
    setOpenedAccordions((prevState: number[]) =>
      prevState.includes(accountTypeId)
        ? prevState.filter((id) => id !== accountTypeId)
        : [...prevState, accountTypeId]
    );
  };

  useEffect(() => {
    if (activeIntegration === null) return;
    fetchMappingData(mappingSearch);
  }, [
    activeIntegration,
    companyId,
    fetchMappingData,
    mappingPage,
    mappingSorting,
  ]);

  const handleAddAccount = async (
    accountName: string,
    accountGroup: number,
    cacCalculation: boolean,
    intercompanyAccount: boolean
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addMasterList(Number(companyId), {
        name: accountName,
        sourceType: SOURCE_TYPE,
        settings: { cacCalculation, intercompanyAccount },
        accountGroup: accountGroup,
      });
      setAddAccountModalActive(false);
      fetchGroupedMasterLists();
    } catch {
      ctx.updateToaster({
        content: 'Failed to add master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMasterAccount = async (accountId: number) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.deleteMasterList(
        Number(companyId),
        accountId,
        SOURCE_TYPE
      );
      fetchGroupedMasterLists();
    } catch {
      ctx.updateToaster({
        content: 'Failed to delete master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenUpdateModal = (account: {
    id: number;
    accountName: string;
    accountGroup?: number;
    cacCalculation: boolean;
    intercompanyAccount: boolean;
  }) => {
    setAccountToUpdate(account);
    setUpdateAccountModalActive(true);
  };

  const handleUpdateMasterAccount = async (
    accountId: number,
    accountName: string,
    accountGroup: number,
    cacCalculation?: boolean,
    intercompanyAccount?: boolean
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.updateMasterList(
        Number(companyId),
        accountId,
        {
          name: accountName,
          sourceType: SOURCE_TYPE,
          settings: { cacCalculation, intercompanyAccount },
          accountGroup: accountGroup,
        }
      );
      setUpdateAccountModalActive(false);
      fetchGroupedMasterLists();
    } catch {
      ctx.updateToaster({
        content: 'Failed to update master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMappingChange = (
    mappingId: number,
    value: MappingMasterList | null
  ) => {
    setIntegrationMappings((prev) => {
      if (!prev) {
        return null;
      }

      const newMappings = prev.map((mapping) =>
        mapping.id === mappingId ? { ...mapping, masterList: value } : mapping
      );
      return newMappings;
    });

    setUpdatedMappings((prev) => {
      const existing = prev.find((mapping) => mapping.id === mappingId);

      if (existing) {
        const updated = prev.map((mapping) =>
          mapping.id === mappingId ? { ...mapping, to: value?.id } : mapping
        );
        return updated;
      }

      const originalMapping = integrationMappings?.find(
        (mapping) => mapping.id === mappingId
      );

      if (!originalMapping) {
        return prev;
      }

      const newMapping = {
        id: mappingId,
        from: originalMapping.name || '',
        to: value?.id,
      };

      return [...prev, newMapping];
    });
  };

  const handleRemap = async (
    updatedMappings: { id: number; from: string; to: number | undefined }[]
  ) => {
    if (!updatedMappings?.length) {
      ctx.updateToaster({
        content: 'No changes to update',
        isError: true,
      });
      return;
    }
    setLoading(true);
    try {
      await ManagerHttpMappingService.remap(Number(companyId), {
        integrationId: Number(activeIntegration),
        sourceType: SOURCE_TYPE,
        updatedMappings,
      });
      setUpdatedMappings([]);
      fetchMappingData(mappingSearch);
      ctx.updateToaster({
        content:
          'The remapping process has begun and may take a few minutes to complete.',
        isError: false,
      });
    } catch {
      ctx.updateToaster({ content: 'Failed to remap', isError: true });
    } finally {
      setLoading(false);
    }
  };

  const handleMappingSearch = () => {
    setMappingPage(1);
    fetchMappingData(mappingSearch);
  };

  const handleChooseBase = async (
    integrationId: string,
    sourceType: SourceType
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addMasterLists(Number(companyId), {
        integrationId,
        sourceType,
      });
      fetchGroupedMasterLists();
    } catch {
      ctx.updateToaster({
        content: 'Failed to add master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleMappingSorting = () => {
    setMappingSorting((prev) => ({
      field: 'name',
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderTableHeaders = () => {
    if (activeIntegration === null) {
      return (
        <tr>
          <th className="master-table-header" style={{ cursor: 'pointer' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>ACCOUNT NAME</span>
            </div>
          </th>
          <th className="master-table-header">GROUP OF THE ACCOUNT</th>
          <th className="master-table-header">CAC CALCULATION</th>
          <th className="master-table-header">INTERCOMPANY ACCOUNT</th>
          <th
            onClick={() => setAddAccountModalActive(true)}
            style={{ cursor: 'pointer', textAlign: 'end' }}
            className="master-table-header"
          >
            <span
              style={{
                textDecoration: 'underline',
                color: 'black',
                fontWeight: 'normal',
                fontSize: '1rem',
                margin: '0 0.5rem',
              }}
            >
              + ACCOUNT
            </span>
          </th>
        </tr>
      );
    }

    return (
      <tr>
        <th
          className="mapping-table-header"
          onClick={toggleMappingSorting}
          style={{ cursor: 'pointer' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>ACCOUNT NAME</span>
            {mappingSorting.order === 'asc' ? (
              <ArrowUp size={20} style={{ marginLeft: '8px' }} />
            ) : (
              <ArrowDown size={20} style={{ marginLeft: '8px' }} />
            )}
          </div>
        </th>
        <th></th>
        <th>ACCOUNT IN FUEL SYSTEM</th>
        <th style={{ textAlign: 'end' }}>
          {updatedMappings?.length > 0 && (
            <UpdatedButtonComponent
              onClick={() => {
                handleRemap(updatedMappings);
              }}
              mini={false}
              type="default"
            >
              UPDATE MAPPING
            </UpdatedButtonComponent>
          )}
        </th>
      </tr>
    );
  };
  const renderTableRows = () => {
    if (activeIntegration === null) {
      return groupedMasterLists.map((accountType, index) => (
        <React.Fragment key={index}>
          <tr>
            <td>
              <div className="account-type-header">
                <span
                  className={
                    isAccordionOpen(accountType.id)
                      ? 'accordion accordion-open'
                      : 'accordion accordion-close'
                  }
                  onClick={() => toggleAccordion(accountType.id)}
                >
                  {isAccordionOpen(accountType.id) ? '-' : '+'}
                </span>
                <span className="groupped-account-type-name">
                  {accountType.name}
                </span>
                {isAccordionOpen(accountType.id) && (
                  <span
                    className="arrow"
                    onClick={() =>
                      toggleMasterSortingByAccountTypeId(accountType.id)
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {accountType.order === 'asc' ? (
                      <ArrowUp size={20} />
                    ) : (
                      <ArrowDown size={20} />
                    )}
                  </span>
                )}
              </div>
            </td>
          </tr>
          {isAccordionOpen(accountType.id) && (
            <>
              {accountType.masterLists.map((account, row) => (
                <tr key={row}>
                  <td className="master-table-row">{account.name}</td>
                  <td className="master-table-row">
                    {account?.group?.name || ''}
                  </td>
                  <td className="master-table-row">
                    {account?.settings?.cacCalculation ? 'YES' : 'NO'}
                  </td>
                  <td className="master-table-row">
                    {account?.settings?.intercompanyAccount ? 'YES' : 'NO'}
                  </td>
                  <td className="actions master-table-row">
                    <Edit3
                      className="edit-icon"
                      onClick={() =>
                        handleOpenUpdateModal({
                          id: account.id,
                          accountName: account.name,
                          accountGroup: account?.group?.id,
                          cacCalculation:
                            account?.settings?.cacCalculation || false,
                          intercompanyAccount:
                            account?.settings?.intercompanyAccount || false,
                        })
                      }
                    />
                    <Trash2
                      className="mapping-delete-icon"
                      onClick={() => handleDeleteMasterAccount(account.id)}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: 'start' }}>
                  <MappingPagination
                    page={accountType.pagination.page}
                    totalCount={accountType.pagination.total}
                    onPageChange={handleChangePageByAccountType}
                    accountTypeId={accountType.id}
                    order={accountType.order}
                  />
                </td>
              </tr>
            </>
          )}
        </React.Fragment>
      ));
    }

    return (
      <>
        {integrationMappings?.map((mapping, index) => (
          <tr key={index}>
            <td className="mapping-account">{mapping.name}</td>
            <td className="mapping-arrow">
              <img
                src={arrowImg}
                alt="arrow"
                style={{ width: '24px', height: '20px' }}
              />
            </td>
            <td className="mapping-fuel-account">
              <CustomSelectWithSearch
                options={allMasterListData}
                value={mapping.masterList || null}
                onChange={(selectedValue: MappingMasterList | null) =>
                  handleMappingChange(mapping.id, selectedValue)
                }
                placeholder="Choose account"
              />
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: 'end' }}>
            <Pagination
              page={mappingPage}
              totalCount={totalMappingCount}
              onPageChange={setMappingPage}
            />
          </td>
        </tr>
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mapping-financial-accounts">
      <div className="mapping-sub-menu">
        <div
          className={`mapping-sub-menu-item ${
            activeIntegration === null ? 'active' : ''
          }`}
          onClick={() => {
            setActiveIntegration(null);
            if (mappingSearch) {
              setMappingSearch('');
              setMappingPage(1);
              fetchMappingData('');
            }
          }}
        >
          master list
        </div>
        {integrations?.map((integration, index) => (
          <div
            key={index}
            className={`mapping-sub-menu-item ${
              activeIntegration === integration.integrationId ? 'active' : ''
            }`}
            onClick={() => {
              setActiveIntegration(integration.integrationId);
            }}
          >
            {`${integration.integrationName} ${integration.integrationId.slice(
              -4
            )}`}
          </div>
        ))}
      </div>
      <div className="mapping-search-action">
        {activeIntegration !== null && (
          <SearchBar
            searchValue={mappingSearch}
            setSearchValue={setMappingSearch}
            placeholder="Type account name"
            onSearch={handleMappingSearch}
          />
        )}
      </div>
      <table
        className={
          activeIntegration === null ? 'master-table' : 'mapping-table'
        }
      >
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      {addAccountModalActive && (
        <AddAccountGroupModal
          onAdd={handleAddAccount}
          onClose={() => setAddAccountModalActive(false)}
          accountTypeOptions={allAccountsGroupsData}
        />
      )}
      {updateAccountModalActive && (
        <UpdateAccountGroupModal
          account={accountToUpdate}
          onSave={handleUpdateMasterAccount}
          onClose={() => setUpdateAccountModalActive(false)}
          accountTypeOptions={allAccountsGroupsData}
        />
      )}
      {chooseBaseModalActive && (
        <ChooseBaseModal
          onClose={() => setChooseBaseModalActive(false)}
          onConfirm={handleChooseBase}
          categories={integrations || []}
          sourceType="ACCOUNT"
        />
      )}
    </div>
  );
};
