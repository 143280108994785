import { StackedBarChart } from '../../../../pages/dashboard/components/cards-content/stacked-chart/stacked-chart';
import {
  DashboardCard,
  NoDataAvailableComonent,
  withWrap,
} from './dashboard-card';
import { HistogramDataset } from '../interfaces/dashboard-card.type';
import { UpdatedStackedChart } from '../../../../pages/dashboard/components/cards-content/stacked-chart/updated-stacked-chart.component';

export class StackedBarCard extends DashboardCard<
  {
    dataset: any;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: HistogramDataset },
    options?: { colors?: string[]; percents?: boolean }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <>
        <p>{data.name}</p>
        <StackedBarChart
          dataset={data.dataset}
          options={options}
          name={data.name}
          identifier={identifier}
        />
      </>
    );
  }
}

export class UpdatedStackedChartCard extends DashboardCard<any, any> {
  constructor(identifier: string, layout: any, data: any, options: any) {
    super(identifier, layout);
    this.component = withWrap(
      <>
        <p>TEST REVENUE STRUCTURE</p>
        <UpdatedStackedChart />
      </>
    );
  }
}
