import {
  HistogramDataset,
  SummaryDataset,
  ComparisonSummaryDataset,
  BalanceSummaryDataset,
} from '../interfaces/dashboard-card.type';
import {
  reverseSign,
  DashboardValueBuilderv2,
  formatDate,
} from '../../../../utils/dashboard/formatters';
import {
  DashboardCashRunwayDataItem,
  DashboardDataItemWithCategory,
  DashboardDataItemWithCounterpart,
} from '../interfaces/dashboard-data-item.interface';
import {
  CashflowDashboardData,
  CashflowDashboardDataType,
} from '../interfaces/cashflow-dashboard-data.interface';
import { findMetricByDate, getLastClosedMonth, getMonthName } from '../utils';
import { BaseDashboardManager } from './base-dashboard-data.manager';

export class CashflowDashboardDataManager extends BaseDashboardManager<
  CashflowDashboardData,
  CashflowDashboardDataType
> {
  constructor(protected readonly dashboardData: CashflowDashboardData) {
    super(dashboardData);
    this.methodMap = new Map<CashflowDashboardDataType, any>([
      [CashflowDashboardDataType.INFLOW_OUTFLOW, this.getInflowOutflowData],
      [CashflowDashboardDataType.CASH_BALANCE, this.getCashBalanceData],
      [CashflowDashboardDataType.RUNWAY, this.getRunwayData],
      [CashflowDashboardDataType.ACCOUNTS, this.getAccountsData],
      [CashflowDashboardDataType.FREE_CASH_FLOW, this.getFreeCashFlowData],
      [CashflowDashboardDataType.BURN_RATE, this.getBurnRateData],
      [CashflowDashboardDataType.CASH_END_MONTH, this.getCashEndSummaryData],
      [CashflowDashboardDataType.FUNDS_NEEDED, this.getFundsNeededSummaryData],
      [
        CashflowDashboardDataType.OPERATING_INFLOW_ACTUAL,
        this.getOperatingInflowActualData,
      ],
      [
        CashflowDashboardDataType.OPERATING_INFLOW_REVENUE,
        this.getOperatingInflowRevenueData,
      ],
      [
        CashflowDashboardDataType.BUDGET_ACTUAL_INFLOW,
        this.getBudgetActualInflowSummaryData,
      ],
      [
        CashflowDashboardDataType.ACCOUNT_RECEIVABLES,
        this.getAccountReceivableSummaryData,
      ],
      [CashflowDashboardDataType.TOP_DEBTORS, this.getTopDebtors],
      [
        CashflowDashboardDataType.OPERATING_OUTFLOW_ACTUAL,
        this.getOperatingOutflowData,
      ],
      [
        CashflowDashboardDataType.BUDGET_ACTUAL_OUTFLOW,
        this.getBudgetActualOutflowSummaryData,
      ],
      [
        CashflowDashboardDataType.BUDGET_ACTUAL_CATEGORY,
        this.getBudgetActualCategoriesData,
      ],
      [
        CashflowDashboardDataType.ACCOUNT_PAYABLES,
        this.getAccountPayableSummaryData,
      ],
      //// [CashflowDashboardDataType.WORKING_CAPITAL, this.getPlStructureData],
      [
        CashflowDashboardDataType.FINANCIAL_INFLOW_OUTFLOW,
        this.getFinancialInflowOutflowData,
      ],
      [
        CashflowDashboardDataType.INVESTING_INFLOW_OUTFLOW,
        this.getInvestingInflowOutflowData,
      ],
      [
        CashflowDashboardDataType.FINANCING_ACTIVITIES,
        this.getFinancialActivitiesSummaryData,
      ],
      [
        CashflowDashboardDataType.INVESTING_ACTIVITIES,
        this.getInvestingActivitiesSummaryData,
      ],
    ]);
  }

  public getCashBalanceData = () => {
    return this.getHistogramSingleData(
      'totalCashBalanceMetrics',
      'cash balance, $',
      false
    );
  };

  public getRunwayData = () => {
    return this.getHistogramSingleData(
      'runwayMetrics',
      'runway, months',
      false
    );
  };

  public getBurnRateData = () => {
    return this.getHistogramSingleData(
      'netBurnRateMetrics',
      'net burn rate, $',
      true
    );
  };

  public getFreeCashFlowData = () => {
    return this.getHistogramSingleData(
      'freeCashFlowActualMetrics',
      'free cash flow, $',
      false
    );
  };

  public getAccountReceivableSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(CashflowDashboardDataType.ACCOUNT_RECEIVABLES);
  };

  public getAccountPayableSummaryData = (): SummaryDataset | null => {
    return this.getSummaryData(CashflowDashboardDataType.ACCOUNT_PAYABLES);
  };

  public getFinancialActivitiesSummaryData =
    (): BalanceSummaryDataset | null => {
      return this.getBalanceSummaryData(
        CashflowDashboardDataType.FINANCING_ACTIVITIES
      );
    };

  public getInvestingActivitiesSummaryData =
    (): BalanceSummaryDataset | null => {
      return this.getBalanceSummaryData(
        CashflowDashboardDataType.INVESTING_ACTIVITIES
      );
    };

  public getBudgetActualInflowSummaryData =
    (): ComparisonSummaryDataset | null => {
      return this.getComparisonSummaryData(
        CashflowDashboardDataType.BUDGET_ACTUAL_INFLOW
      );
    };

  public getBudgetActualOutflowSummaryData =
    (): ComparisonSummaryDataset | null => {
      return this.getComparisonSummaryData(
        CashflowDashboardDataType.BUDGET_ACTUAL_OUTFLOW
      );
    };

  public getFundsNeededSummaryData = (): BalanceSummaryDataset | null => {
    return this.getBalanceSummaryData(CashflowDashboardDataType.FUNDS_NEEDED);
  };

  public getBudgetActualCategoriesData = () => {
    return this.prepareTableData(
      'outflowByCategoriesActualMetrics',
      'outflowByCategoriesProjectedMetrics'
    );
  };

  public getInflowOutflowData = (): HistogramDataset => {
    const { cashInflowActualMetrics, cashOutflowActualMetrics } =
      this.dashboardData;

    const inflowData = this.getDateFormMonthData(cashInflowActualMetrics);

    const inflowVsOutflow = inflowData.map((inflowMetric) => {
      const { year, month, value: inflowValue } = inflowMetric;
      const outflowMetric = cashOutflowActualMetrics.find(
        (outflowMetric) =>
          outflowMetric.year === year && outflowMetric.month === month
      );

      return {
        date: `${year}-${month}`,
        'inflow, $': inflowValue,
        'outflow, $': reverseSign(outflowMetric?.value || 0),
      };
    });

    const nonZeroKeys = ['inflow, $', 'outflow, $'].filter((key) =>
      inflowVsOutflow.some((entry: any) => entry[key] !== 0)
    );

    return {
      data: inflowVsOutflow,
      keys: nonZeroKeys,
      indexBy: 'date',
    };
  };

  public getOperatingOutflowData = (): HistogramDataset => {
    const {
      operatingCashOutflowActualMetrics,
      operatingCashOutflowProjectedMetrics,
    } = this.dashboardData;

    const actualData = this.getDateFormMonthData(
      operatingCashOutflowActualMetrics
    );

    const actualVsBudget = actualData.map((actualMetric) => {
      return {
        date: this.processDataItemWithDate(actualMetric),
        'projected, $':
          findMetricByDate(
            operatingCashOutflowProjectedMetrics,
            actualMetric.year,
            actualMetric.month
          )?.value || 0,
        'actual, $': actualMetric.value,
      };
    });

    const nonZeroKeys = ['projected, $', 'actual, $'].filter((key) =>
      actualVsBudget.some((entry: any) => entry[key] !== 0)
    );

    return {
      data: actualVsBudget,
      keys: nonZeroKeys,
      indexBy: 'date',
    };
  };

  public getFinancialInflowOutflowData = (): HistogramDataset => {
    const {
      financialCashInflowActualMetrics,
      financialCashOutflowActualMetrics,
    } = this.dashboardData;

    return this.getActualProjectedMetricData(
      financialCashInflowActualMetrics,
      financialCashOutflowActualMetrics,
      'inflow, $',
      'outflow, $'
    );
  };

  public getInvestingInflowOutflowData = (): HistogramDataset => {
    const {
      investingCashInflowActualMetrics,
      investingCashOutflowActualMetrics,
    } = this.dashboardData;

    return this.getActualProjectedMetricData(
      investingCashInflowActualMetrics,
      investingCashOutflowActualMetrics,
      'inflow, $',
      'outflow, $'
    );
  };

  public getTopDebtors = () => {
    return this.getTopCategoriesTableData(
      'accountReceivableEndBalanceByCustomerMetrics'
    );
  };

  private getTopCategoriesTableData(key: keyof CashflowDashboardData) {
    const tableDataItems = this.dashboardData[
      key
    ] as DashboardDataItemWithCounterpart[];

    const { year, month } = getLastClosedMonth(this.endDate);
    const monthTableData = this.getDateFormMonthData(tableDataItems).filter(
      (item) => item.month === month && item.year === year
    ) as DashboardDataItemWithCounterpart[];

    monthTableData.sort((a, b) => b.value - a.value);

    const result = monthTableData
      .filter((item) => item.customer)
      .map((item) => {
        const formattedValue = new DashboardValueBuilderv2(item.value)
          .formatValue(1)
          .shouldIncludeLetter(true)
          .getValue();

        return {
          category: item.customer,
          value: formattedValue,
        };
      })
      .slice(0, 5);

    return {
      rows: result,
      date: formatDate(`${year}-${month}`),
    };
  }

  private getSummaryData(
    type: CashflowDashboardDataType,
    signType: 'percent' | 'value' = 'value'
  ): SummaryDataset | null {
    const map = new Map<CashflowDashboardDataType, keyof CashflowDashboardData>(
      [
        [
          CashflowDashboardDataType.ACCOUNT_RECEIVABLES,
          'totalAccountReceivableBalanceMetrics',
        ],
        [
          CashflowDashboardDataType.ACCOUNT_PAYABLES,
          'totalAccountPayableBalanceMetrics',
        ],
      ]
    );

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const result = this.getHeaderData(
      map.get(type) as keyof CashflowDashboardData
    );

    return {
      value: result.monthData.value || 0,
      prevMonthData: result.prevMonthData.value || 0,
      year: result?.year || 0,
      month: result?.month || 0,
      signType,
    };
  }

  private getBalanceSummaryData(
    type: CashflowDashboardDataType
  ): BalanceSummaryDataset | null {
    const map = new Map<CashflowDashboardDataType, keyof CashflowDashboardData>(
      [
        [CashflowDashboardDataType.FUNDS_NEEDED, 'cashRunwayMetrics'],
        [
          CashflowDashboardDataType.FINANCING_ACTIVITIES,
          'financialFreeCashFlowActualMetrics',
        ],
        [
          CashflowDashboardDataType.INVESTING_ACTIVITIES,
          'investingFreeCashFlowActualMetrics',
        ],
      ]
    );

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const result = this.getHeaderData(
      map.get(type) as keyof CashflowDashboardData
    );

    return {
      value: result.monthData.value,
    };
  }

  private getCashEndSummaryData = (): BalanceSummaryDataset | null => {
    const { year, month } = getLastClosedMonth(this.endDate);
    const cashRunwayMetrics = this.dashboardData[
      'cashRunwayMetrics'
    ] as DashboardCashRunwayDataItem[];
    const cashRunwayItem = cashRunwayMetrics.find(
      (item) => item.year === year && item.month === month
    );

    if (cashRunwayItem) {
      const { cashRunwayYear, cashRunwayMonth } = cashRunwayItem;

      return {
        value: `${getMonthName(cashRunwayMonth)} ${cashRunwayYear}`,
      };
    }

    return {
      value: '',
    };
  };

  private getComparisonSummaryData(
    type: CashflowDashboardDataType
  ): ComparisonSummaryDataset | null {
    const map = new Map<
      CashflowDashboardDataType,
      [keyof CashflowDashboardData, keyof CashflowDashboardData]
    >([
      [
        CashflowDashboardDataType.BUDGET_ACTUAL_INFLOW,
        [
          'operatingCashInflowActualMetrics',
          'operatingCashInflowProjectedMetrics',
        ],
      ],
      [
        CashflowDashboardDataType.BUDGET_ACTUAL_OUTFLOW,
        [
          'operatingCashOutflowActualMetrics',
          'operatingCashOutflowProjectedMetrics',
        ],
      ],
    ]);

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const keys = map.get(type);
    if (!keys?.length) {
      return null;
    }
    const first = this.getHeaderData(keys[0]);
    const second = this.getHeaderData(keys[1]);

    return {
      firstValue: first?.monthData?.value || 0,
      secondValue: second?.monthData?.value || 0,
      year: first?.year || 0,
      month: first?.month || 0,
    };
  }

  public getOperatingInflowActualData = (): HistogramDataset => {
    const {
      operatingCashInflowActualMetrics,
      operatingCashInflowProjectedMetrics,
    } = this.dashboardData;

    return this.getActualProjectedMetricData(
      operatingCashInflowProjectedMetrics,
      operatingCashInflowActualMetrics,
      'projected, $',
      'actual, $'
    );
  };

  public getOperatingInflowRevenueData = (): HistogramDataset => {
    const { operatingCashInflowActualMetrics, revenueActualMetrics } =
      this.dashboardData;

    return this.getActualProjectedMetricData(
      operatingCashInflowActualMetrics,
      revenueActualMetrics,
      'inflow, $',
      'revenue, $'
    );
  };

  private getHistogramSingleData(
    keyFrom: keyof CashflowDashboardData,
    keyTo: string,
    reverse = false
  ) {
    const data = this.dashboardData[keyFrom];
    const filteredData = this.getDateFormMonthData(data);

    const remappedData = filteredData.map((dataItem) => {
      return {
        date: this.processDataItemWithDate(dataItem),
        [keyTo]: reverse ? -1 * dataItem.value : dataItem.value,
      };
    });

    return {
      data: remappedData,
      keys: [keyTo],
      indexBy: 'date',
    };
  }

  public getAccountsData = () => {
    const { cashBalanceByAccountsMetrics } = this.dashboardData;

    const { year, month } = getLastClosedMonth(this.endDate);
    const lastMonthData = this.getDateFormMonthData(
      cashBalanceByAccountsMetrics
    ).filter(
      (d) => d.year === year && d.month === month
    ) as DashboardDataItemWithCategory[];

    if (!lastMonthData.length) {
      return {
        data: [],
        keys: ['value'],
        indexBy: 'category',
        date: formatDate(`${year}-${month}`),
      };
    }

    const data = lastMonthData.map((d) => ({
      date: this.processDataItemWithDate(d),
      value: d.value,
      category: d.category,
    }));

    return {
      data,
      keys: ['value'],
      indexBy: 'category',
      date: formatDate(`${year}-${month}`),
    };
  };

  private prepareTableData(
    actualKey: keyof CashflowDashboardData,
    budgetKey: keyof CashflowDashboardData
  ) {
    const actualDataItems = this.dashboardData[
      actualKey
    ] as DashboardDataItemWithCategory[];
    const budgetDataItems = this.dashboardData[
      budgetKey
    ] as DashboardDataItemWithCategory[];

    const actualMonthData = this.getDateFormMonthData(actualDataItems);
    const budgetMonthData = this.getDateFormMonthData(budgetDataItems);

    const actualLastMonthDataSet = actualDataItems.filter((item) =>
      actualMonthData.some(
        (filteredItem) =>
          filteredItem.year === item.year && filteredItem.month === item.month
      )
    );

    const budgetLastMonthDataSet = budgetDataItems.filter((item) =>
      budgetMonthData.some(
        (filteredItem) =>
          filteredItem.year === item.year && filteredItem.month === item.month
      )
    );

    const budgetMap = new Map<string, DashboardDataItemWithCategory>();
    budgetLastMonthDataSet.forEach((item) => {
      budgetMap.set(item.category, item);
    });

    actualLastMonthDataSet.sort((a, b) => a.value - b.value);

    const result = actualLastMonthDataSet.map((actualItem) => {
      const budgetItem = budgetMap.get(actualItem.category);
      const actualValue = actualItem.value;
      const budgetValue = budgetItem ? budgetItem.value : 0;

      const formattedActual = new DashboardValueBuilderv2(actualValue)
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();

      const formattedBudget = new DashboardValueBuilderv2(budgetValue)
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();

      const deviation =
        budgetValue !== 0 ? (actualValue / budgetValue - 1) * 100 : 0;

      return {
        category: actualItem.category,
        actual: {
          type: 'ACTUAL',
          value: formattedActual,
        },
        budget: {
          type: 'BUDGET',
          value: formattedBudget,
        },
        deviation: Number(deviation.toFixed(2)),
      };
    });

    const totalActual = actualLastMonthDataSet.reduce(
      (acc, item) => acc + item.value,
      0
    );
    const totalBudget = budgetLastMonthDataSet.reduce(
      (acc, item) => acc + (item.value || 0),
      0
    );

    return {
      rows: result,
      totalActual: new DashboardValueBuilderv2(totalActual)
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue(),
      totalBudget: new DashboardValueBuilderv2(totalBudget)
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue(),
      totalDeviation:
        totalBudget === 0
          ? 0
          : Number(((totalActual / totalBudget - 1) * 100).toFixed(2)),
    };
  }
}
