import {
  CardTable,
  CategoriesCardTable,
} from '../../../../pages/dashboard/components/cards-content/table/table.component';
import { DashboardCard, NoDataAvailableComonent } from './dashboard-card';
import {
  DynamicTableDataset,
  OpexCogsTableDataset,
  OutflowCategoriesTableDataset,
} from '../interfaces/dashboard-card.type';
import { DynamicCardTable } from '../../../../pages/dashboard/components/cards-content/table/dynamic-table';

export class TableCard extends DashboardCard<
  {
    dataset: OpexCogsTableDataset[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { dataset: OpexCogsTableDataset; name: string }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = <CardTable name={data.name} dataset={data.dataset} />;
  }
}

export class CategoriesTableCard extends DashboardCard<
  {
    dataset: OutflowCategoriesTableDataset[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { dataset: OutflowCategoriesTableDataset; name: string }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = (
      <CategoriesCardTable name={data.name} dataset={data.dataset} />
    );
  }
}

export class DynamicTableCard extends DashboardCard<
  {
    dataset: DynamicTableDataset;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: DynamicTableDataset }
  ) {
    super(identifier, layout);
    if (!data.dataset) {
      this.component = null;
      return;
    }
    const { columns, data: tableData } = data.dataset;
    this.component = (
      <DynamicCardTable
        tableName={data.name}
        columns={columns}
        data={tableData}
      />
    );
  }
}
