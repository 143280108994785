import { RaceBarChart } from '../../../../pages/dashboard/components/cards-content/race-chart';
import {
  DashboardCard,
  NoDataAvailableComonent,
  withWrap,
} from './dashboard-card';
import { RaceChartDataset } from '../interfaces/dashboard-card.type';

export class RaceChartCard extends DashboardCard<
  {
    dataset: RaceChartDataset;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: RaceChartDataset }
  ) {
    super(identifier, layout);
    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = withWrap(
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p>{data.name}</p>
          <span
            style={{
              color: '#1D1B2080',
              fontSize: '10px',
            }}
          >
            for {data.dataset.date}
          </span>
        </div>
        <RaceBarChart dataset={data.dataset} />
      </>
    );
  }
}
