import React from 'react';
import { BarCustomLayerProps, BarDatum, ResponsiveBar } from '@nivo/bar';
import { DefaultTooltipBody } from './default-tooltip';
import {
  DashboardValueBuilderv2,
  reformatToThousands,
} from '../../../../utils/dashboard/formatters';
import { RaceChartDataset } from '../../../../hooks/client/dashboard/interfaces/dashboard-card.type';

const CustomLabelLayer = (
  props: BarCustomLayerProps<BarDatum> & { withSign: boolean }
) => {
  const { bars, width, withSign } = props;
  const paddingRight = 15;
  const paddingLeft = 5;

  return (
    <g>
      {bars.map((bar) => (
        <g key={bar.key}>
          <text
            x={paddingLeft}
            y={bar.y + bar.height / 2}
            textAnchor="start"
            dominantBaseline="central"
            style={{
              fill: 'black',
              fontSize: 12,
            }}
          >
            {bar.data.indexValue}
          </text>

          <text
            x={width - paddingRight}
            y={bar.y + bar.height / 2}
            textAnchor="end"
            dominantBaseline="central"
            style={{
              fill: 'black',
              fontSize: 12,
            }}
          >
            {withSign
              ? reformatToThousands(bar.data.value || 0, true)
              : reformatToThousands(bar.data.value || 0)}
          </text>
        </g>
      ))}
    </g>
  );
};

export const RaceBarChart = ({ dataset }: { dataset: RaceChartDataset }) => {
  const { keys, indexBy, data, withSign = true } = dataset;

  const sortedData = data.sort((a, b) => {
    const totalA = keys.reduce((acc, key) => acc + a[key], 0);
    const totalB = keys.reduce((acc, key) => acc + b[key], 0);
    return totalA - totalB;
  });

  const baseColor = 'rgba(238, 195, 10, 1)';
  const splitColor = baseColor.split(',');

  const c = [];

  for (let i = 0; i < sortedData.length; i++) {
    c.push(
      `${splitColor[0]}, ${splitColor[1]}, ${splitColor[2]}, ${1 - i * 0.1}`
    );
  }
  const raceChartColors = c.reverse();

  const CustomLabelLayerWithSign = (
    layerProps: BarCustomLayerProps<BarDatum>
  ) => <CustomLabelLayer {...layerProps} withSign={withSign} />;

  return (
    <div className={'drag-cancel'} style={{ height: '100%', width: '100%' }}>
      <ResponsiveBar
        data={sortedData}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 10, right: 50, bottom: 50, left: 10 }}
        layout="horizontal"
        colors={(d) => {
          return raceChartColors[d.index % raceChartColors.length];
        }}
        colorBy={'indexValue'}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={120}
        labelTextColor="black"
        label={(d) => `${d.value}`}
        animate={true}
        padding={0.2}
        tooltip={({ value, indexValue, color }) => {
          return (
            <DefaultTooltipBody color={color}>
              <p>{indexValue}</p>
              <p>
                {new DashboardValueBuilderv2(value)
                  .formatValue(2)
                  .shouldIncludeLetter(true)
                  .getValue()}
              </p>
            </DefaultTooltipBody>
          );
        }}
        layers={[
          'axes',
          'bars',
          CustomLabelLayerWithSign,
          'markers',
          'legends',
        ]}
      />
    </div>
  );
};
