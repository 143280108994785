import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
} from './dashboard-data-item.interface';

export interface SnapshotDashboardData {
  cashInflowActualMetrics: DashboardDataItem[];
  cashOutflowActualMetrics: DashboardDataItem[];
  cashInflowBudgetedMetrics: DashboardDataItem[];
  cashOutflowBudgetedMetrics: DashboardDataItem[];
  totalCashBalanceMetrics: DashboardDataItem[];
  runwayMetrics: DashboardDataItem[];
  netBurnRateMetrics: DashboardDataItem[];
  opexCategoriesMetrics: DashboardDataItemWithCategory[];
  cogsCategoriesMetrics: DashboardDataItemWithCategory[];
  revenueActualMetrics: DashboardDataItem[];
  revenueProjectedMetrics: DashboardDataItem[];
  costOfSalesActualMetrics: DashboardDataItem[];
  opexActualMetrics: DashboardDataItem[];
  netProfitMarginMetrics: DashboardDataItem[];
  grossProfitActualMetrics: DashboardDataItem[];
  grossProfitMarginActualMetrics: DashboardDataItem[];
  netProfitActualMetrics: DashboardDataItem[];
  revenueByCategoriesMetrics: DashboardDataItemWithCategory[];
  revenueGrowthActualMetricsAsPercent: DashboardDataItem[];
  expensesGrowthMetrics: DashboardDataItem[];
  uniqueCustomersMetrics: DashboardDataItem[];
  arpuMetrics: DashboardDataItem[];
  customersChurnRateMetrics: DashboardDataItem[];
  customersLifetimeValueMetrics: DashboardDataItem[];
  cacMetrics: DashboardDataItem[];
  ltvCacMetrics: DashboardDataItem[];
  totalExpensesForCategoriesMetrics: DashboardDataItemWithCategory[];
}

export enum SnapshotDashboardDataType {
  INFLOW_OUTFLOW = 'INFLOW_OUTFLOW',
  LTV_SUMMARY = 'LTV_SUMMARY',
  CAC_SUMMARY = 'CAC_SUMMARY',
  CHURN_RATE_SUMMARY = 'CHURN_RATE_SUMMARY',
  LTV_CAC_SUMMARY = 'LTV_CAC_SUMMARY',
  NET_BURN_RATE_SUMMARY = 'NET_BURN_RATE_SUMMARY',
  RUNWAY = 'RUNWAY',
  NET_BURN_RATE = 'NET_BURN_RATE',
  EXPENSES_VS_BUDGET = 'EXPENSES_VS_BUDGET',
  AVB_OUTFLOW = 'AVB_OUTFLOW',
  AVB_INFLOW = 'AVB_INFLOW',
  REVENUE = 'REVENUE',
  REVENUE_GROWTH = 'REVENUE_GROWTH',
  PL = 'PL',
  COGS_OPEX = 'COGS_OPEX',
  CUSTOMERS_SUMMARY = 'CUSTOMERS_SUMMARY',
  ARPU_SUMMARY = 'ARPU_SUMMARY',
  OPEX_TABLE = 'OPEX_TABLE',
  COGS_TABLE = 'COGS_TABLE',
  MARGIN = 'MARGIN',
  NET_INCOME_PROFITABILITY = 'NET_INCOME_PROFITABILITY',
  PL_STRUCTURE_TABLE = 'PL_STRUCTURE_TABLE',
  WATERFALL_1 = 'WATERFALL_1',
  TEST_COMBINED_V1 = 'TEST_COMBINED_V1',
  TEST_COMBINED_V2 = 'TEST_COMBINED_V2',
}
