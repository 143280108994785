import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
} from './dashboard-data-item.interface';

export interface CashflowDashboardData {
  cashInflowActualMetrics: DashboardDataItem[];
  cashOutflowActualMetrics: DashboardDataItem[];
  totalCashBalanceMetrics: DashboardDataItem[];
  freeCashFlowActualMetrics: DashboardDataItem[];
  runwayMetrics: DashboardDataItem[];
  netBurnRateMetrics: DashboardDataItem[];
  cashRunwayMetrics: [];
  minimumCashBalanceMetrics: DashboardDataItem[];
  cashBalanceByAccountsMetrics: DashboardDataItemWithCategory[];
  operatingCashInflowActualMetrics: DashboardDataItem[];
  operatingCashInflowProjectedMetrics: DashboardDataItem[];
  revenueActualMetrics: DashboardDataItem[];
  totalAccountReceivableBalanceMetrics: DashboardDataItem[];
  accountReceivableEndBalanceByCustomerMetrics: DashboardDataItem[];
  operatingCashOutflowActualMetrics: DashboardDataItem[];
  operatingCashOutflowProjectedMetrics: DashboardDataItem[];
  totalAccountPayableBalanceMetrics: DashboardDataItem[];
  outflowByCategoriesActualMetrics: DashboardDataItemWithCategory[];
  outflowByCategoriesProjectedMetrics: DashboardDataItemWithCategory[];
  financialFreeCashFlowActualMetrics: DashboardDataItem[];
  investingFreeCashFlowActualMetrics: DashboardDataItem[];
  financialCashInflowActualMetrics: DashboardDataItem[];
  financialCashOutflowActualMetrics: DashboardDataItem[];
  investingCashInflowActualMetrics: DashboardDataItem[];
  investingCashOutflowActualMetrics: DashboardDataItem[];
}

export enum CashflowDashboardDataType {
  INFLOW_OUTFLOW = 'INFLOW_OUTFLOW',
  CASH_BALANCE = 'CASH_BALANCE',
  RUNWAY = 'RUNWAY',
  ACCOUNTS = 'ACCOUNTS',
  FREE_CASH_FLOW = 'FREE_CASH_FLOW',
  BURN_RATE = 'BURN_RATE',
  CASH_END_MONTH = 'CASH_END_MONTH',
  FUNDS_NEEDED = 'FUNDS_NEEDED',
  OPERATING_INFLOW_ACTUAL = 'OPERATING_INFLOW_ACTUAL',
  OPERATING_INFLOW_REVENUE = 'OPERATING_INFLOW_REVENUE',
  BUDGET_ACTUAL_INFLOW = 'BUDGET_ACTUAL_INFLOW',
  ACCOUNT_RECEIVABLES = 'ACCOUNT_RECEIVABLES',
  TOP_DEBTORS = 'TOP_DEBTORS',
  OPERATING_OUTFLOW_ACTUAL = 'OPERATING_OUTFLOW_ACTUAL',
  BUDGET_ACTUAL_OUTFLOW = 'BUDGET_ACTUAL_OUTFLOW',
  BUDGET_ACTUAL_CATEGORY = 'BUDGET_ACTUAL_CATEGORY',
  ACCOUNT_PAYABLES = 'ACCOUNT_PAYABLES',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  FINANCIAL_INFLOW_OUTFLOW = 'FINANCIAL_INFLOW_OUTFLOW',
  INVESTING_INFLOW_OUTFLOW = 'INVESTING_INFLOW_OUTFLOW',
  FINANCING_ACTIVITIES = 'FINANCING_ACTIVITIES',
  INVESTING_ACTIVITIES = 'INVESTING_ACTIVITIES',
}
