import { ResponsiveLine } from '@nivo/line';
import { DefaultTooltipBody } from './default-tooltip';
import {
  convertToDateRange,
  formatDate,
  reformatToThousands,
  reformatToThousandsWithoutSign,
} from '../../../../utils/dashboard/formatters';
import { DrilldownContext } from '../../../../hooks/client/dashboard/drill-down/drill-down.context';
import { useContext } from 'react';
import { DateTime } from 'luxon';

interface LineProps {
  data: any;
  options?: any;
  name: string;
  identifier: string;
}

const marginByLegendPosition = (position: 'standard' | 'right', keys) => {
  const longestKey = keys.reduce((a, b) =>
    a.length > b.length ? a : b
  ).length;

  if (position === 'right') {
    return {
      top: 20,
      right: 100 + 20 + longestKey * 2,
      bottom: 60,
      left: 30,
    };
  }

  if (position === 'standard') {
    return { top: 50, right: 20, bottom: 60, left: 30 };
  }
};

const buildLegend = (position: 'standard' | 'right') => {
  if (position === 'right') {
    return [
      {
        dataFrom: 'keys',
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 80,
        itemWidth: 100,
        itemHeight: 40,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ];
  }
  if (position === 'standard') {
    return [
      {
        anchor: 'top-right',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: -50,
        itemsSpacing: 80,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'right-to-left',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ];
  }
};

const CustomPointLabelLayer =
  (properties: any) =>
  ({ points }: any) =>
    (
      <>
        {points.map((point: any, index: number) => {
          const val = properties.options?.percents
            ? ((point.data.y as number) * 100).toFixed(0) + '%'
            : reformatToThousands(point.data.y);

          return (
            <g key={index} transform={`translate(${point.x}, ${point.y - 10})`}>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 10,
                  fill: 'white',
                  strokeWidth: 3,
                  stroke: 'white',
                  paintOrder: 'stroke',
                  pointerEvents: 'none',
                }}
              >
                {val}
              </text>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 10,
                  fill: 'black',
                  pointerEvents: 'none',
                }}
              >
                {val}
              </text>
            </g>
          );
        })}
      </>
    );

export const LineChart = ({ data, options, name, identifier }: LineProps) => {
  const getAllUniqueKeys = (
    datasets: { id: string; data: { x: string; y: string }[] }[]
  ) => {
    const keys = new Set<string>();
    datasets.map((dataset) => {
      dataset.data.map((point) => keys.add(point.x));
    });
    return Array.from(keys).sort((a, b) => {
      const dateA = DateTime.fromFormat(a, 'yyyy-MM');
      const dateB = DateTime.fromFormat(b, 'yyyy-MM');
      return dateA.toMillis() - dateB.toMillis();
    });
  };

  const normalizeData = (
    datasets: { id: string; data: { x: string; y: string }[] }[],
    allKeys: string[]
  ) => {
    return datasets.map((dataset) => ({
      id: dataset.id,
      data: allKeys.map((key) => {
        const found = dataset.data.find((point) => point.x === key);
        return found ? { x: key, y: parseFloat(found.y) } : { x: key, y: 0 };
      }),
    }));
  };

  const showLabels = true;
  const drillDownContext = useContext(DrilldownContext);
  const { dataset } = data;

  const keys = dataset.map((d: { key: string }) => d.key);

  const usedLegendPosition = options?.legendPosition || 'standard';

  const allYValues = dataset.flatMap((d: { data: any[] }) =>
    d.data.map((point) => point.y)
  );
  const yMin = Math.min(...allYValues);
  const yMax = Math.max(...allYValues);

  const lines = dataset.map((data: any) => {
    return {
      id: data.key,
      data: data.data,
    };
  });

  const normalizedData = normalizeData(lines, getAllUniqueKeys(lines));

  const handleClick = (event: any, point: any) => {
    event.stopPropagation();

    const date = point.data.x;
    if (drillDownContext?.setData) {
      drillDownContext.setData({
        date: convertToDateRange(date),
        name: name || '',
        identifier: identifier || '',
      });
    }
  };

  return (
    <div className={'drag-cancel'} style={{ width: '100%', height: '100%' }}>
      <ResponsiveLine
        data={normalizedData}
        margin={marginByLegendPosition(usedLegendPosition, keys)}
        enableGridY={false}
        enableGridX={false}
        onClick={(point, event) => handleClick(event, point)}
        curve={data?.curve || 'linear'}
        enablePoints={
          data?.enablePoints !== undefined ? data?.enablePoints : true
        }
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: yMin,
          max: yMax,
          stacked: false,
          reverse: false,
        }}
        axisLeft={null}
        axisBottom={{
          format: (value: string) => formatDate(value),
          tickValues: getAllUniqueKeys(lines),
          tickSize: 0,
          tickPadding: 30,
        }}
        colors={options?.colors || { scheme: 'greens' }}
        useMesh={true}
        tooltip={({ point }) => {
          const { serieId, data } = point;
          const value = options?.percents
            ? `${((data.y as number) * 100).toFixed(0)}%`
            : data.y;
          const date = data.x;
          let marginLeft = '0px';
          const chartWidth = 800;
          const pointPositionX = point.x;

          if (pointPositionX < 100) {
            marginLeft = '150px';
          } else if (pointPositionX > chartWidth - 100) {
            marginLeft = '-150px';
          }
          return (
            <div style={{ marginLeft, position: 'relative' }}>
              <DefaultTooltipBody color={point.color}>
                <p>{serieId}</p>
                <p>{formatDate(date as string)}</p>
                <p>{reformatToThousandsWithoutSign(value as number)}</p>
              </DefaultTooltipBody>
            </div>
          );
        }}
        enableTouchCrosshair={false}
        layers={[
          'grid',
          'markers',
          'areas',
          'lines',
          ...(showLabels ? [CustomPointLabelLayer({ data, options })] : []),
          'points',
          'slices',
          'mesh',
          'axes',
          'legends',
        ]}
        legends={buildLegend(usedLegendPosition)}
      />
    </div>
  );
};
