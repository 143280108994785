import { ResponsivePie } from '@nivo/pie';
import { DashboardCard, withWrap } from './dashboard-card';
import { PieChartDataset } from '../interfaces/dashboard-card.type';
import { reformatToThousands } from '../../../../utils/dashboard/formatters';

const PieChartValue = ({
  value,
  name,
}: {
  value: number | null;
  name: string;
}) => {
  const color = name === 'BUDGET' ? '#00000080' : 'black';

  return (
    <>
      <p style={{ color }} className={'pie-chart-card-name'}>
        {name}
      </p>
      <p style={{ color }} className={'pie-chart-card-value'}>
        {reformatToThousands(value || 0)}
      </p>
    </>
  );
};

export const PieChartContent = ({
  dataset,
  name,
}: {
  dataset: PieChartDataset;
  name: string;
}) => {
  const { name1, name2, value2, value1 } = dataset;

  const mapFromValues = [
    { id: name1, label: name1, value: value1, color: '#29b250' },
    {
      id: name2,
      label: name2,
      value: value2 === undefined ? 'No Data Available' : value2,
      color: '#dddddd',
    },
  ];

  return withWrap(
    <div className="pie-chart-card">
      <div className={'data'}>
        <p>{name}</p>
        <div>
          <div>
            <PieChartValue value={value1} name={name1} />
          </div>
          <div>
            <PieChartValue value={value2} name={name2} />
          </div>
        </div>
      </div>
      <div className={'pie-chart'}>
        <ResponsivePie
          sortByValue={true}
          data={mapFromValues}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={0}
          colors={(d) => d.data.color}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          animate={false}
          enableArcLabels={false}
          motionConfig={'molasses'}
          transitionMode={'innerRadius'}
          enableArcLinkLabels={false}
        />
      </div>
    </div>
  );
};

export class PieChartCard extends DashboardCard<
  {
    dataset: any;
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: any }
  ) {
    super(identifier, layout);

    if (!data.dataset || !data.dataset.value1 || !data.dataset.value2) {
      this.component = null;
      return;
    }

    this.component = (
      <PieChartContent name={data.name} dataset={data.dataset} />
    );
  }
}
