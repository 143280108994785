import { useContext, useEffect, useState } from 'react';
import { CardFactory } from '../card.factory';
import { DashboardCard } from '../dashboard-cards/dashboard-card';
import { CashflowDashboardData } from '../interfaces/cashflow-dashboard-data.interface';
import { CashflowDashboardDataManager } from '../data-managers/cashflow-dashboard-data.manager';
import { RoleContext } from '../../../../context/role.context';
import { useParams } from 'react-router-dom';
import { useDashboardStructure } from '../dashboard-structure.hook';
import { fetchDashboardData } from './utils';

const fetchDataForCard = async (
  cardSettings: any,
  dashboardData: CashflowDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dashboardDataManager = new CashflowDashboardDataManager(dashboardData);
  dashboardDataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );

  const { identifier, name } = cardSettings;

  try {
    const dataResult = dashboardDataManager.getData(identifier);

    return {
      ...cardSettings,
      dataset: {
        name,
        dataset: dataResult,
      },
    };
  } catch (error) {
    return { ...cardSettings, name, dataset: {} };
  }
};

export const dashboardHeaderData = (
  dashboardData: CashflowDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dataManager = new CashflowDashboardDataManager(dashboardData);
  dataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );
  return {
    inflow: dataManager.getHeaderData('cashInflowActualMetrics'),
    outflow: dataManager.getHeaderData('cashOutflowActualMetrics'),
    freeCashFlow: dataManager.getHeaderData('freeCashFlowActualMetrics'),
    burnRate: dataManager.getHeaderData('netBurnRateMetrics'),
    cashbalance: dataManager.getHeaderData('totalCashBalanceMetrics'),
    runwayMetrics: dataManager.getHeaderData('runwayMetrics'),
  };
};

export const useCashflowDashboard = (
  dateRange: { startDate: Date; endDate: Date } | null
) => {
  const role = useContext(RoleContext);
  const { companyId } = useParams();
  const { getDashboardStructure } = useDashboardStructure('CASHFLOW');
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<{
    generalCards: DashboardCard<unknown, unknown>[];
    operatingInflowCards: DashboardCard<unknown, unknown>[];
    operatingOutflowCards: DashboardCard<unknown, unknown>[];
    financialInvestingCards: DashboardCard<unknown, unknown>[];
    headerMetrics: any | null;
  }>({
    generalCards: [],
    operatingInflowCards: [],
    operatingOutflowCards: [],
    financialInvestingCards: [],
    headerMetrics: null,
  });

  const getCardSettingsAndBuild = async () => {
    setLoading(true);
    try {
      const dashboardData = await fetchDashboardData<CashflowDashboardData>(
        role,
        companyId,
        'Cashflow'
      );
      const generalCards = [];
      const operatingInflowCards = [];
      const operatingOutflowCards = [];
      const financialInvestingCards = [];
      const headerMetrics = dashboardHeaderData(dashboardData, dateRange);

      const factory = new CardFactory();
      const cardSettings = await getDashboardStructure();
      if (!cardSettings) {
        return;
      }

      for (const card of cardSettings) {
        const cardWithData = await fetchDataForCard(
          card,
          dashboardData,
          dateRange
        );
        const {
          type,
          identifier,
          layout,
          dataset,
          dashboardType,
          options,
          name,
          type: cardType,
        } = cardWithData;

        if (
          dataset.dataset?.data &&
          dataset.dataset?.data?.length === 0 &&
          !['SummaryCard', 'ComparisonSummaryCard'].includes(type)
        ) {
          continue;
        }

        if (
          dataset.dataset?.rows &&
          dataset.dataset?.rows?.length === 0 &&
          !['TopCategoriesBalanceCard'].includes(type)
        ) {
          continue;
        }

        const newCard = factory.createCard(
          type,
          identifier,
          layout,
          dataset,
          options
        );

        newCard.setProperties({
          name,
          type: cardType,
          dashboardType,
        });

        if (newCard.component === null) {
          continue;
        }

        switch (dashboardType) {
          case 'GENERAL':
            generalCards.push(newCard);
            break;
          case 'OPERATING INFLOW':
            operatingInflowCards.push(newCard);
            break;
          case 'OPERATING OUTFLOW':
            operatingOutflowCards.push(newCard);
            break;
          case 'FINANCIAL AND INVESTING ACTIVITY':
            financialInvestingCards.push(newCard);
            break;
          default:
            generalCards.push(newCard);
            break;
        }
      }

      setCards({
        generalCards,
        operatingInflowCards,
        operatingOutflowCards,
        financialInvestingCards,
        headerMetrics,
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCardSettingsAndBuild();
    }

    return () => {
      isMounted = false;
    };
  }, [dateRange]);

  return {
    cards,
    loading,
  };
};
