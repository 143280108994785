import {
  DashboardDataItem,
  YearlyProductDataItem,
} from './dashboard-data-item.interface';

export interface RevenueAnalysisDashboardData {
  revenueActualMetrics: DashboardDataItem[];
  revenueProjectedMetrics: DashboardDataItem[];
  revenueByCustomersMetrics: DashboardDataItem[];
  activeCustomersMetrics: DashboardDataItem[];
  churnRateInRevenueMetrics: DashboardDataItem[];
  arpuMetrics: DashboardDataItem[];
  ltvCacMetrics: DashboardDataItem[];
  revenueGrowthActualMetrics: DashboardDataItem[];
  revenueGrowthProjectedMetrics: DashboardDataItem[];
  newRevenueActualMetrics: DashboardDataItem[];
  totalUpsalesMetrics: DashboardDataItem[];
  churnedRevenueActualMetrics: DashboardDataItem[];
  totalDowngradesMetrics: DashboardDataItem[];
  newCustomersActualMetrics: DashboardDataItem[];
  arpuNewCustomersMetrics: DashboardDataItem[];
  arpuByEachProductMetrics: DashboardDataItem[];
  churnedCustomersActualMetrics: DashboardDataItem[];
  churnRateInNumberMetrics: DashboardDataItem[];
  threeMonthAverageInChurnRevenueMetrics: DashboardDataItem[];
  retentionCurveInRateMetrics: { monthBucket: number; retentionRate: number }[];
  yearlyChurnRate: number;
  retentionCurveInRateByProductMetrics: {
    product: string;
    monthBucket: number;
    retentionRate: number;
  }[];
  newRevenueMetrics: DashboardDataItem[];
  churnRevenueMetrics: DashboardDataItem[];
  existingRevenueMetrics: DashboardDataItem[];
  yearlyChurnRateByProductMetrics: YearlyProductDataItem[];
  ltvByProductsMetrics: DashboardDataItem[];
  ltvMetrics: DashboardDataItem[];
  ltByProductsMetrics: DashboardDataItem[];
  ltMetrics: DashboardDataItem[];
  cacActualMetrics: DashboardDataItem[];
}

export enum RevenueAnalysisDashboardDataType {
  REVENUE_ANALYSIS = 'REVENUE_ANALYSIS',
  REVENUE_GROWTH_ANALYSIS = 'REVENUE_GROWTH_ANALYSIS',
  NEW_REVENUE = 'NEW_REVENUE',
  CHURN_REVENUE = 'CHURN_REVENUE',
  MRR_BREAKDOWN = 'MRR_BREAKDOWN',
  CHURN_BREAKDOWN = 'CHURN_BREAKDOWN',
  CHURN_RATE_FOR_LOST_MRR_1 = 'CHURN_RATE_FOR_LOST_MRR_1',
  USERS_EOP = 'USERS_EOP',
  NEW_USERS_EOP = 'NEW_USERS_EOP',
  ARPU = 'ARPU',
  ARPU_PER_NEW_USER = 'ARPU_PER_NEW_USER',
  ARPU_BY_PRODUCTS_LINE = 'ARPU_BY_PRODUCTS_LINE',
  ARPU_BY_PRODUCTS = 'ARPU_BY_PRODUCTS',
  USERS_EOP_BAR = 'USERS_EOP_BAR',
  USERS_BREAKDOWN = 'USERS_BREAKDOWN',
  NEW_USERS_SUMMARY = 'NEW_USERS_SUMMARY',
  EXISTING_USERS_SUMMARY = 'EXISTING_USERS_SUMMARY',
  CHURN_USERS_SUMMARY = 'CHURN_USERS_SUMMARY',
  CHURN_RATE_FOR_QTY_OF_USERS = 'CHURN_RATE_FOR_QTY_OF_USERS',
  LOST_MRR = 'LOST_MRR',
  THREE_MONTH_AVG_LOST_MRR = 'THREE_MONTH_AVG_LOST_MRR',
  LOST_USERS_SUMMARY = 'LOST_USERS_SUMMARY',
  CHURN_RATE_FOR_LOST_MRR_2 = 'CHURN_RATE_FOR_LOST_MRR_2',
  REVENUE_CURVE = 'REVENUE_CURVE',
  YEARLY_RET_RATE = 'YEARLY_RET_RATE',
  YEARLY_CHURN_RATE = 'YEARLY_CHURN_RATE',
  COGS_HEATMAP = 'COGS_HEATMAP',
  YEARLY_CHURN_RATE_BY_PRODUCTS = 'YEARLY_CHURN_RATE_BY_PRODUCTS',
  LTV_BY_PRODUCTS = 'LTV_BY_PRODUCTS',
  LTV = 'LTV',
  LT_BY_PRODUCTS = 'LT_BY_PRODUCTS',
  AVG_LT = 'AVG_LT',
  LTV_CAC_COMBINED = 'LTV_CAC_COMBINED',
  LT_SUMMARY_REVENUE = 'LT_SUMMARY_REVENUE',
  LTV_SUMMARY_REVENUE = 'LTV_SUMMARY_REVENUE',
  LTV_CAC_SUMMARY_REVENUE = 'LTV_CAC_SUMMARY_REVENUE',
}
