import { DateTime } from 'luxon';
import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
  DashboardDataItemWithStage,
} from '../interfaces/dashboard-data-item.interface';
import { getLastClosedMonth } from '../utils';

export class BaseDashboardManager<T, K> {
  protected date: string | null = null;
  protected startDate: Date | null = null;
  protected endDate: Date | null = null;

  protected methodMap: Map<K, any> = new Map();

  constructor(protected readonly dashboardData: T) {}

  public getData(type: K): any {
    const method = this.methodMap.get(type as K);
    if (!method) {
      console.warn(`No method available for type: ${String(type)}`);
      return null;
    }
    return method();
  }

  public setDate(date: string): void {
    this.date = date;
  }

  public setDateRange(startDate: Date | null, endDate: Date | null): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public processDataItemWithDate(item: {
    year: number;
    month: number;
  }): string {
    return DateTime.local(item.year, item.month, 1).toFormat('yyyy-MM');
  }

  public getDateFormMonthData(
    dataItems:
      | DashboardDataItem[]
      | DashboardDataItemWithCategory[]
      | DashboardDataItemWithStage[]
  ):
    | DashboardDataItem[]
    | DashboardDataItemWithCategory[]
    | DashboardDataItemWithStage[] {
    if (!dataItems || dataItems.length === 0) {
      return [];
    }

    if (this.startDate && this.endDate) {
      return dataItems?.filter((item) => {
        const itemDate = new Date(item.year, item.month - 1);

        const isSameMonthAsStart =
          itemDate.getFullYear() === this.startDate?.getFullYear() &&
          itemDate.getMonth() === this.startDate.getMonth();
        const isSameMonthAsEnd =
          itemDate.getFullYear() === this.endDate?.getFullYear() &&
          itemDate.getMonth() === this.endDate.getMonth();

        return (
          isSameMonthAsStart ||
          isSameMonthAsEnd ||
          (this.startDate &&
            this.endDate &&
            itemDate >= this.startDate &&
            itemDate <= this.endDate)
        );
      });
    }

    if (this.date) {
      return dataItems.filter((it) => {
        return `${it.year}-${it.month}` === this.date;
      });
    }

    return dataItems;
  }

  public getHeaderData<K extends keyof T>(
    key: K
  ): {
    month: number;
    year: number;
    monthData: { value: number };
    prevMonthData: { value: number };
  } {
    const data = this.dashboardData[key] as Array<{
      year: number;
      month: number;
      value: number;
    }>;

    if (!this.isDashboardDataItemArray(data)) {
      throw new Error('Invalid data type');
    }

    const { month: targetMonth, year: targetYear } = getLastClosedMonth(
      this.endDate
    );

    const monthData = this.getDateFormMonthData(data).find(
      (d) => d.month === targetMonth && d.year === targetYear
    );

    const prevMonthData = data.find(
      (item) => item.year === targetYear && item.month === targetMonth - 1
    );

    if (!monthData) {
      return {
        month: targetMonth,
        year: targetYear,
        monthData: { value: 0 },
        prevMonthData: { value: prevMonthData?.value || 0 },
      };
    }

    return {
      month: monthData.month || 0,
      year: monthData.year,
      monthData: { value: monthData.value || 0 },
      prevMonthData: { value: prevMonthData?.value || 0 },
    };
  }

  public warnMissingMethod(type: string): void {
    console.warn(`No method available for type: ${type}`);
  }

  public isDashboardDataItemArray(data: any): data is DashboardDataItem[] {
    return (
      Array.isArray(data) &&
      data.every(
        (item) =>
          typeof item === 'object' &&
          'month' in item &&
          'year' in item &&
          'value' in item
      )
    );
  }

  public calculateFutureMonthAndYear(
    initialMonth: number,
    initialYear: number,
    monthsToAdd: number
  ): { month: number; year: number } {
    const totalMonths = initialMonth + monthsToAdd;
    const yearsToAdd = Math.floor(totalMonths / 12);
    const remainingMonths = Math.round(totalMonths % 12);

    const futureYear = initialYear + yearsToAdd;
    const futureMonth = remainingMonths === 0 ? 12 : remainingMonths;

    return { month: futureMonth, year: futureYear };
  }

  public getActualProjectedMetricData = (
    actualMetrics: Array<any>,
    projectedMetrics: Array<any>,
    actualKey: string,
    projectedKey: string
  ) => {
    const filteredActualMetrics = this.getDateFormMonthData(actualMetrics);
    const filteredProjectedMetrics =
      this.getDateFormMonthData(projectedMetrics);

    const allDates = new Set(
      [...filteredActualMetrics, ...filteredProjectedMetrics].map((metric) =>
        this.processDataItemWithDate(metric)
      )
    );

    const combinedMetrics = Array.from(allDates).map((date) => {
      const actualMetric = filteredActualMetrics.find(
        (metric) => this.processDataItemWithDate(metric) === date
      );
      const projectedMetric = filteredProjectedMetrics.find(
        (metric) => this.processDataItemWithDate(metric) === date
      );

      const actualValue = actualMetric ? actualMetric.value : 0;
      const projectedValue = projectedMetric ? projectedMetric.value : 0;

      return { date, [actualKey]: actualValue, [projectedKey]: projectedValue };
    });

    combinedMetrics.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const filteredMetrics = combinedMetrics.filter(
      (item) => item[actualKey] !== 0 || item[projectedKey] !== 0
    );

    const hasNonZeroProjected = filteredMetrics.some(
      (item) => item[projectedKey] !== 0
    );

    const keys = [actualKey];
    if (hasNonZeroProjected) {
      keys.unshift(projectedKey);
    }

    return {
      data: filteredMetrics,
      keys,
      indexBy: 'date',
    };
  };

  public getGrowthPercentData = (
    actualMetrics: DashboardDataItem[],
    projectedMetrics: DashboardDataItem[],
    actualLabel: string,
    projectedLabel: string,
    options: {
      curve: string;
      enablePoints: boolean;
      showLabels: boolean;
    },
    reverse = false
  ) => {
    const processMetrics = (metrics: DashboardDataItem[]) => {
      return this.getDateFormMonthData(metrics).map(
        ({ year, month, value }) => ({
          x: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
          y: parseFloat((value / 100).toFixed(2)),
        })
      );
    };

    const fillMissingDates = (
      data: { x: string; y: number }[],
      allMonths: string[]
    ) => {
      const dataMap = new Map(data.map((item) => [item.x, item.y]));

      return allMonths.map((month) => ({
        x: month,
        y: dataMap.get(month) ?? 0,
      }));
    };

    const actualData = processMetrics(actualMetrics);
    const projectedData =
      projectedMetrics.length > 0 ? processMetrics(projectedMetrics) : [];

    const allMonths = Array.from(
      new Set([...actualData, ...projectedData].map((item) => item.x))
    ).sort(
      (a, b) =>
        DateTime.fromFormat(a, 'yyyy-MM').toMillis() -
        DateTime.fromFormat(b, 'yyyy-MM').toMillis()
    );

    const filledActualData = fillMissingDates(actualData, allMonths);
    const filledProjectedData = fillMissingDates(projectedData, allMonths);

    const isAllZero = (data: { x: string; y: number }[]) =>
      data.every((point) => point.y === 0);

    const filteredWithValues = [
      projectedMetrics.length > 0 && !isAllZero(filledProjectedData)
        ? { key: projectedLabel, data: filledProjectedData }
        : null,
      !isAllZero(filledActualData)
        ? { key: actualLabel, data: filledActualData }
        : null,
    ].filter((item) => item !== null);

    const result = reverse ? filteredWithValues.reverse() : filteredWithValues;

    return {
      dataset: result,
      ...options,
    };
  };
}
