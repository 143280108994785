import { TopCategoriesBalanceCardTable } from '../../../../pages/dashboard/components/cards-content/table/table.component';
import { CategoriesBalanceTableDataset } from '../interfaces/dashboard-card.type';
import { DashboardCard, NoDataAvailableComonent } from './dashboard-card';

export class TopCategoriesBalanceCard extends DashboardCard<
  {
    dataset: CategoriesBalanceTableDataset[];
  },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { dataset: CategoriesBalanceTableDataset; name: string }
  ) {
    super(identifier, layout);

    if (!data.dataset) {
      this.component = <NoDataAvailableComonent name={data.name} />;
      return;
    }

    this.component = (
      <TopCategoriesBalanceCardTable name={data.name} dataset={data.dataset} />
    );
  }
}
