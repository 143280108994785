import {
  HistogramDataset,
  SummaryDataset,
} from '../interfaces/dashboard-card.type';
import { DateTime } from 'luxon';
import { reverseSign } from '../../../../utils/dashboard/formatters';
import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
} from '../interfaces/dashboard-data-item.interface';
import {
  PlDashboardData,
  PlDashboardDataType,
} from '../interfaces/pl-dashboard-data.interface';
import { getLongestDateRange } from '../utils';
import { BaseDashboardManager } from './base-dashboard-data.manager';

export class PlDashboardDataManager extends BaseDashboardManager<
  PlDashboardData,
  PlDashboardDataType
> {
  constructor(protected readonly dashboardData: PlDashboardData) {
    super(dashboardData);
    this.methodMap = new Map<PlDashboardDataType, any>([
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE,
        this.getRevenueProjectedActual,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_NET_PROFIT,
        this.getNetProfitProjectedActual,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN,
        this.getRevenueBreakdownActualProjected,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT,
        this.getRevenueGrowthPercent,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_REVENUE_GROWTH_SUM,
        this.getRevenueGrowthSum,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN,
        this.getGrossProfitMarginProjectedActual,
      ],
      [
        PlDashboardDataType.PROJECTED_ACTUAL_COGS,
        this.getCostOfSalesProjectedActual,
      ],
      [PlDashboardDataType.REVENUE_COGS_GROWTH, this.getRevenueCogsGrowth],
      [PlDashboardDataType.OPEX_SUMMARY, this.getOpexSummary],
      [PlDashboardDataType.REVENUE_OPEX_GROWTH, this.getRevenueOpexGrowth],
      [PlDashboardDataType.EBITDA, this.getEbitda],
      [PlDashboardDataType.NET_PROFIT_MARGIN, this.getNetProfitMargin],
      [PlDashboardDataType.PL_WATERFALL, this.getPlWaterfallData],
    ]);
  }

  private getProjectedActualData = (
    actualMetrics: DashboardDataItem[],
    projectedMetrics: DashboardDataItem[],
    projectedLabel: string,
    actualLabel: string,
    xAxis = false,
    reverse = false
  ): HistogramDataset => {
    const actualData = this.getDateFormMonthData(actualMetrics);

    const actualVsProjected = actualData.map((actualMetric) => {
      const { year, month, value: actualValue } = actualMetric;
      const projectedMetric = projectedMetrics.find(
        (projectedMetric) =>
          projectedMetric.year === year && projectedMetric.month === month
      );

      return {
        date: `${year}-${String(month).padStart(2, '0')}`,
        [actualLabel]: actualValue,
        [projectedLabel]: projectedMetric?.value || 0,
      };
    });

    actualVsProjected.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const nonZeroKeys = [projectedLabel, actualLabel].filter((key) =>
      actualVsProjected.some((entry: any) => entry[key] !== 0)
    );

    const keys = reverse ? nonZeroKeys.reverse() : nonZeroKeys;

    return {
      data: actualVsProjected,
      keys: keys,
      indexBy: 'date',
      xAxis,
    };
  };

  public getRevenueProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.revenueActualMetrics,
      this.dashboardData.revenueProjectedMetrics,
      'projected, $',
      'actual, $'
    );
  };

  public getNetProfitProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.netProfitActualMetrics,
      this.dashboardData.netProfitProjectedMetrics,
      'projected, $',
      'actual, $',
      true
    );
  };

  public getGrossProfitMarginProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.grossProfitMarginActualMetrics,
      this.dashboardData.grossProfitMarginProjectedMetrics,
      'projected gross profit margin, %',
      'actual gross profit margin, %'
    );
  };

  public getCostOfSalesProjectedActual = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.costOfSalesActualMetrics.map((data) => ({
        ...data,
        value: reverseSign(data.value),
      })),
      this.dashboardData.costOfSalesProjectedMetrics.map((data) => ({
        ...data,
        value: reverseSign(data.value),
      })),
      'Projected COGS, $',
      'Actual COGS, $'
    );
  };

  public getRevenueBreakdownActualProjected = () => {
    const { revenueByCategoriesMetrics } = this.dashboardData;

    const filteredRevenueByCategoriesMetrics = this.getDateFormMonthData(
      revenueByCategoriesMetrics
    ) as DashboardDataItemWithCategory[];

    const dateRange = getLongestDateRange([filteredRevenueByCategoriesMetrics]);

    const formattedCategoryRevenueByDate = [];

    let current = dateRange.start;

    while (current <= dateRange.end) {
      const year = current.year;
      const month = current.month;

      const revenueForCurrentMonth = filteredRevenueByCategoriesMetrics.filter(
        (item: DashboardDataItemWithCategory) =>
          item.year === year && item.month === month
      ) as DashboardDataItemWithCategory[];

      const formattedEntry: { [key: string]: any } = {
        date: `${year}-${month}`,
      };

      revenueForCurrentMonth.forEach((item) => {
        formattedEntry[item.category] = item.value;
      });

      formattedCategoryRevenueByDate.push(formattedEntry);

      current = current?.plus({ months: 1 });
    }

    const allCategories = Array.from(
      new Set(
        filteredRevenueByCategoriesMetrics.map(
          (item: DashboardDataItemWithCategory) => item.category
        )
      )
    );

    return {
      keys: allCategories,
      indexBy: 'date',
      data: formattedCategoryRevenueByDate,
    };
  };

  public getRevenueGrowthPercent = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetricsAsPercent,
      this.dashboardData.revenueGrowthProjectedMetricsAsPercent,
      'actual revenue growth, %',
      'projected revenue growth, %',
      {
        curve: 'natural',
        enablePoints: false,
        showLabels: false,
      },
      true
    );
  };

  public getRevenueGrowthSum = (): HistogramDataset => {
    return this.getProjectedActualData(
      this.dashboardData.revenueGrowthActualMetricsAsSum,
      this.dashboardData.revenueGrowthProjectedMetricsAsSum,
      'projected revenue growth, $',
      'actual revenue growth, $',
      false,
      true
    );
  };

  public getRevenueCogsGrowth = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetricsAsPercent,
      this.dashboardData.cogsGrowthMetrics,
      'Revenue Growth, %',
      'COGS Growth, %',
      {
        curve: 'natural',
        enablePoints: false,
        showLabels: false,
      }
    );
  };

  public getRevenueOpexGrowth = () => {
    return this.getGrowthPercentData(
      this.dashboardData.revenueGrowthActualMetricsAsPercent,
      this.dashboardData.opexGrowthMetrics,
      'Revenue Growth, %',
      'OPEX Growth, %',
      {
        curve: 'natural',
        enablePoints: false,
        showLabels: false,
      }
    );
  };

  public getOpexSummary = (): SummaryDataset | null => {
    return this.getSummaryData(PlDashboardDataType.OPEX_SUMMARY);
  };

  private getSummaryData(
    type: PlDashboardDataType,
    signType: 'percent' | 'value' = 'value'
  ): SummaryDataset | null {
    const map = new Map<PlDashboardDataType, keyof PlDashboardData>([
      [PlDashboardDataType.OPEX_SUMMARY, 'opexActualMetrics'],
    ]);

    if (!map.has(type)) {
      console.warn(`No data available for type: ${type}`);
      return null;
    }

    const result = this.getHeaderData(map.get(type) as keyof PlDashboardData);

    return {
      value: result.monthData.value || 0,
      prevMonthData: result.prevMonthData.value || 0,
      year: result?.year || 0,
      month: result?.month || 0,
      signType,
    };
  }

  public getEbitda = () => {
    return this.getHistogramSingleData('ebitdaActualMetrics', 'EBITDA, $');
  };

  public getNetProfitMargin = () => {
    const { netProfitMarginMetrics, netProfitActualMetrics } =
      this.dashboardData;

    const filteredNetProfitActualMetrics = this.getDateFormMonthData(
      netProfitActualMetrics
    );
    const filteredNetProfitMarginMetrics = this.getDateFormMonthData(
      netProfitMarginMetrics
    );

    const marginData = filteredNetProfitActualMetrics.map((metric) => {
      const { year, month, value } = metric;

      return {
        date: DateTime.local(year, month, 1).toFormat('yyyy-MM'),
        'net profit, $': value,
      };
    });

    const marginDataWithLine = marginData.map((item) => {
      return {
        ...item,
        'net profit, $': item['net profit, $'],
        'net profit margin, %':
          filteredNetProfitMarginMetrics.find(
            (metric) => item.date === this.processDataItemWithDate(metric)
          )?.value || 0,
      };
    });

    marginDataWithLine.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return {
      data: marginDataWithLine,
      keys: ['net profit, $', 'net profit margin, %'],
    };
  };

  private getHistogramSingleData(
    keyFrom: keyof PlDashboardData,
    keyTo: string,
    reverse = false
  ) {
    const data = this.dashboardData[keyFrom];
    const filteredData = this.getDateFormMonthData(data);

    const remappedData = filteredData.map((dataItem) => {
      return {
        date: this.processDataItemWithDate(dataItem),
        [keyTo]: reverse ? reverseSign(dataItem.value) : dataItem.value,
      };
    });

    return {
      data: remappedData,
      keys: [keyTo],
      indexBy: 'date',
    };
  }

  public getPlWaterfallData = () => {
    const revenueData = this.getHeaderData('revenueActualMetrics');
    const otherIncomeData = this.getHeaderData('otherIncomeActualMetrics');
    const costOfSalesData = this.getHeaderData('costOfSalesActualMetrics');
    const opexData = this.getHeaderData('opexActualMetrics');
    const otherExpensesData = this.getHeaderData('otherExpensesActualMetrics');
    const grossProfitData = this.getHeaderData('grossProfitActualMetrics');
    const ebitdaData = this.getHeaderData('ebitdaActualMetrics');
    const netProfitData = this.getHeaderData('netProfitActualMetrics');

    return {
      data: [
        {
          name: 'Revenue',
          uv: revenueData.monthData.value,
          pv: 0,
          amt: revenueData.monthData.value,
        },
        {
          name: 'Cogs',
          uv: costOfSalesData.monthData.value,
          pv: revenueData.monthData.value,
        },
        {
          name: 'Gross profit',
          uv: grossProfitData.monthData.value,
          pv: 0,
        },
        {
          name: 'Opex',
          uv: opexData.monthData.value,
          pv: grossProfitData.monthData.value,
        },
        {
          name: 'Ebitda',
          uv: ebitdaData.monthData.value,
          pv: 0,
        },
        {
          name: 'Other income',
          uv: otherIncomeData.monthData.value,
          pv: ebitdaData.monthData.value,
        },
        {
          name: 'Other expenses',
          uv: otherExpensesData.monthData.value,
          pv: ebitdaData.monthData.value,
        },
        {
          name: 'Net profit',
          uv: netProfitData.monthData.value,
          pv: 0,
          amt: revenueData.monthData.value,
        },
      ],
    };
  };
}
