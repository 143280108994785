import { instanceWithToken } from '../api';
import { AxiosData } from '../api-services/interfaces';
import { FilterDto } from './types/filter.dto';
import {
  MappingMasterList,
  MappingSource,
  IntegrationMapping,
  SourceType,
  AccountGroupSource,
  MappingMasterListPaginatedResponse,
  AccountGroupPaginatedResponse,
  MappingPaginatedResponse,
  AccountGroup,
} from './types/mapping';

export class ManagerHttpMappingService {
  public static getAvailableIntegrations(
    companyId: number,
    sourceType: SourceType
  ): Promise<AxiosData<MappingSource[]>> {
    return instanceWithToken.get(
      `mappings/${companyId}/available-sources?sourceType=${sourceType}`
    );
  }

  public static getMappingList(
    companyId: number,
    sourceType: SourceType,
    integrationId: number,
    filterDto?: FilterDto
  ): Promise<MappingPaginatedResponse> {
    return instanceWithToken.post(
      `mappings/${companyId}/list/${integrationId}?sourceType=${sourceType}`,
      {
        ...filterDto,
      }
    );
  }

  public static createMapping(companyId: number, data: IntegrationMapping) {
    return instanceWithToken.post(`mappings/${companyId}`, { ...data });
  }

  public static remap(
    companyId: number,
    data: {
      integrationId: number;
      sourceType: SourceType;
      updatedMappings: { id: number; from: string; to: number | undefined }[];
    }
  ) {
    return instanceWithToken.post(`mappings/${companyId}/remap`, { ...data });
  }

  public static filterMasterList(
    companyId: number,
    sourceType: SourceType,
    filterDto?: FilterDto
  ): Promise<MappingMasterListPaginatedResponse> {
    return instanceWithToken.post(
      `mappings/${companyId}/master-lists/filter?sourceType=${sourceType}`,
      {
        ...filterDto,
      }
    );
  }

  public static getGroupedMasterLists(
    companyId: number,
    accountTypeId?: number,
    filterDto?: {
      page?: number;
      limit?: number;
      order?: 'asc' | 'desc';
    }
  ) {
    const queryParams = new URLSearchParams();

    if (accountTypeId) {
      queryParams.append('accountTypeId', accountTypeId.toString());
    }

    if (filterDto) {
      if (filterDto.page) queryParams.append('page', filterDto.page.toString());
      if (filterDto.order) queryParams.append(`order`, filterDto.order);
      if (filterDto.limit)
        queryParams.append('limit', filterDto.limit.toString());
    }

    const queryString = queryParams.toString();
    return instanceWithToken.get(
      `mappings/${companyId}/master-lists/grouped${
        queryString ? `?${queryString}` : ''
      }`
    );
  }

  public static getMasterList(
    companyId: number,
    sourceType: SourceType
  ): Promise<AxiosData<MappingMasterList[]>> {
    return instanceWithToken.get(
      `mappings/${companyId}/master-lists?sourceType=${sourceType}`
    );
  }

  public static filterAccountGroups(
    companyId: number,
    filterDto?: FilterDto
  ): Promise<AccountGroupPaginatedResponse> {
    return instanceWithToken.post(
      `mappings/${companyId}/account-groups/filter`,
      {
        ...filterDto,
      }
    );
  }

  public static getAccountGroups(
    companyId: number
  ): Promise<AxiosData<AccountGroup[]>> {
    return instanceWithToken.get(`mappings/${companyId}/account-groups`);
  }

  public static addAccountGroup(
    companyId: number,
    data: { name: string; type: number; source?: AccountGroupSource }
  ) {
    return instanceWithToken.post(
      `mappings/${companyId}/account-groups/single`,
      {
        ...data,
      }
    );
  }

  public static updateAccountGroup(
    companyId: number,
    accountGroupId: number,
    data: { name: string; type: number; source?: AccountGroupSource }
  ) {
    return instanceWithToken.put(
      `mappings/${companyId}/account-groups/${accountGroupId}`,
      { ...data }
    );
  }

  public static deleteAccountGroup(companyId: number, accountGroupId: number) {
    return instanceWithToken.delete(
      `mappings/${companyId}/account-groups/${accountGroupId}`
    );
  }

  public static getAccountTypes(
    companyId: number
  ): Promise<AxiosData<{ id: number; name: string }[]>> {
    return instanceWithToken.get(`mappings/${companyId}/account-types`);
  }

  public static addMasterLists(
    companyId: number,
    data: { integrationId: string; sourceType: SourceType }
  ) {
    return instanceWithToken.post(`mappings/${companyId}/master-lists`, {
      ...data,
    });
  }

  public static addMasterList(
    companyId: number,
    data: {
      name: string;
      sourceType: SourceType;
      settings: object;
      accountGroup?: number;
    }
  ) {
    return instanceWithToken.post(`mappings/${companyId}/master-lists/single`, {
      ...data,
    });
  }

  public static updateMasterList(
    companyId: number,
    masterListId: number,
    data: {
      name: string;
      sourceType: SourceType;
      settings: object;
      accountGroup?: number;
    }
  ) {
    return instanceWithToken.put(
      `mappings/${companyId}/master-lists/${masterListId}`,
      { ...data }
    );
  }

  public static deleteMasterList(
    companyId: number,
    masterListId: number,
    sourceType: SourceType
  ) {
    return instanceWithToken.delete(
      `mappings/${companyId}/master-lists/${masterListId}?sourceType=${sourceType}`
    );
  }
}
