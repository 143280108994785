import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
  DashboardDataItemWithStage,
} from './dashboard-data-item.interface';

export interface SalesMarketingDashboardData {
  cacActualMetrics: DashboardDataItem[];
  newRevenueActualMetrics: DashboardDataItem[];
  newWonCustomersMetrics: DashboardDataItem[];
  mqlCustomersMetrics: DashboardDataItem[];
  mqlWonConversionRateMetrics: DashboardDataItem[];
  mqlSqlAccumulatedConversionRateMetrics: DashboardDataItem[];
  mqlWonAccumulatedConversionRateMetrics: DashboardDataItem[];
  ltvCacMetrics: DashboardDataItem[];
  cacActualGrowthMetrics: DashboardDataItem[];
  revenueGrowthActualMetrics: DashboardDataItem[];
  ltvMetrics: DashboardDataItem[];
  sqlCustomersMetrics: DashboardDataItem[];
  averageNewWonDealValueMetrics: DashboardDataItem[];
  averageDaysToCloseMetrics: DashboardDataItem[];
  customersByStagesMetrics: DashboardDataItemWithStage[];
  revenueByStagesMetrics: DashboardDataItemWithStage[];
  newLostCustomersMetrics: DashboardDataItem[];
  averageLostDealValueMetrics: DashboardDataItem[];
  totalExpenseBySalesMarketingCategoryMetrics: DashboardDataItemWithCategory[];
  acquistionCostsMetrics: DashboardDataItem[];
  acquistionCostsGrowthMetrics: DashboardDataItem[];
  costPerMqlMetrics: DashboardDataItem[];
  costPerSqlMetrics: DashboardDataItem[];
  averageDaysByStageMetrics: DashboardDataItemWithStage[];
  conversionByStageMetrics: DashboardDataItemWithStage[];
  roiMetrics: DashboardDataItem[];
}

export enum SalesMarketingDashboardDataType {
  SM_NEW_REVENUE = 'SM_NEW_REVENUE',
  SM_WON_DEALS = 'SM_WON_DEALS',
  EXPENSES = 'EXPENSES',
  SALES_MARKETING_EXPENSES = 'SALES_MARKETING_EXPENSES',
  SM_REVENUE_GROWTH = 'SM_REVENUE_GROWTH',
  AVG_REVENUE_GROWTH = 'AVG_REVENUE_GROWTH',
  AVG_EXPENSES_GROWTH = 'AVG_EXPENSES_GROWTH',
  LTV_CAC_COMBINED = 'LTV_CAC_COMBINED',
  CONVERSION_MQL_WON = 'CONVERSION_MQL_WON',
  MQL = 'MQL',
  COST_PER_MQL = 'COST_PER_MQL',
  SQL = 'SQL',
  COST_PER_SQL = 'COST_PER_SQL',
  MQL_SUMMARY = 'MQL_SUMMARY',
  SQL_SUMMARY = 'SQL_SUMMARY',
  PER_MQL_SUMMARY = 'PER_MQL_SUMMARY',
  PER_SQL_SUMMARY = 'PER_SQL_SUMMARY',
  CONVERSION_MQL_SQL = 'CONVERSION_MQL_SQL',
  CONVERSION_MQL_SQL_SUMMARY = 'CONVERSION_MQL_SQL_SUMMARY',
  NEW_REVENUE_SUMMARY = 'NEW_REVENUE_SUMMARY',
  WON_DEALS_SUMMARY = 'WON_DEALS_SUMMARY',
  AVG_DEAL_VALUE_SUMMARY = 'AVG_DEAL_VALUE_SUMMARY',
  AVG_DEAL_VALUE = 'AVG_DEAL_VALUE',
  SALES_CYCLE = 'SALES_CYCLE',
  SALES_CYCLE_BY_STAGE = 'SALES_CYCLE_BY_STAGE',
  SALES_FUNNEL_CONVERSION_RATES = 'SALES_FUNNEL_CONVERSION_RATES',
  SALES_FUNNEL_DEALS_NUMBER = 'SALES_FUNNEL_DEALS_NUMBER',
  SALES_FUNNEL_DEAL_VALUE = 'SALES_FUNNEL_DEAL_VALUE',
  LOST_DEALS = 'LOST_DEALS',
  LOST_VALUE = 'LOST_VALUE',
  CONVERSION_MQL_WON_SUMMARY = 'CONVERSION_MQL_WON_SUMMARY',
  ROI_SUMMARY = 'ROI_SUMMARY',
  SALES_NEW_REVENUE = 'SALES_NEW_REVENUE',
  SALES_WON_DEALS = 'SALES_WON_DEALS',
  SOURCES_NEW_REVENUE_SUMMARY = 'SOURCES_NEW_REVENUE_SUMMARY',
}
