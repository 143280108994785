import { ResponsiveBar } from '@nivo/bar';
import { DefaultHistoTooltip } from '../default-tooltip';
import { CustomBarLabel } from './custom-bar-lablel';
import { CustomLineTooltip } from './custom-histo-line';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  convertToDateRange,
  formatDate,
  reformatArrayToPercents,
} from '../../../../../utils/dashboard/formatters';
import { DrilldownContext } from '../../../../../hooks/client/dashboard/drill-down/drill-down.context';
import { DateTime } from 'luxon';
import { HistogramProperties } from '../../../../../hooks/client/dashboard/dashboard-cards/bar-chart-card';

type HistoProps = HistogramProperties & {
  options:
    | {
        colors?: string | undefined;
        percents?: boolean;
        withFixedValue?: number;
      }
    | undefined;
};

const CustomZeroLineLayer = ({ yScale, height }) => (
  <line
    x1="0"
    x2="100%"
    y1={yScale(0)}
    y2={yScale(0)}
    stroke="#D9D9D9"
    strokeWidth={1}
    strokeDasharray="0 0"
  />
);

type DataPoint = {
  date: string;
  [key: string]: string | number;
};

function sortByDateAsc(data: DataPoint[]): DataPoint[] {
  return data.sort((a, b) => {
    const dateA = DateTime.fromFormat(a.date, 'yyyy-MM');
    const dateB = DateTime.fromFormat(b.date, 'yyyy-MM');
    return dateA.toMillis() - dateB.toMillis();
  });
}

export const Histogram = (properties: HistoProps) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const drillDownContext = useContext(DrilldownContext);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setChartWidth(entries[0].contentRect.width);
      }
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  if (
    !properties.dataset ||
    !properties.dataset.data.length ||
    !properties.dataset.data.length
  )
    return <div>No data provided for the reporting month</div>;

  const {
    dataset: { data, keys, indexBy, extraLineData, xAxis },
    options,
  } = properties;

  const handleClick = (bar: any, event: any) => {
    event.stopPropagation();

    const date = bar.indexValue;

    if (drillDownContext?.setData) {
      drillDownContext.setData({
        date: convertToDateRange(date),
        name: properties.name || '',
        identifier: properties.identifier || '',
      });
    }
  };

  const sortedData = sortByDateAsc(data);

  const reformattedData = reformatArrayToPercents(
    sortedData,
    options?.percents
  );

  return (
    <div
      className={'drag-cancel'}
      ref={chartContainerRef}
      style={{ width: '100%', height: '100%' }}
    >
      <ResponsiveBar
        onMouseEnter={(_datum, event) => {
          event.currentTarget.style.cursor = 'pointer';
        }}
        enableLabel={false}
        enableGridY={false}
        data={reformattedData}
        keys={keys}
        indexBy={indexBy}
        onClick={handleClick}
        colors={options?.colors || { scheme: 'greens' }}
        margin={{ top: 80, right: 0, bottom: 60, left: 0 }}
        padding={0.3}
        animate={true}
        groupMode={'grouped'}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 15,
          legendPosition: 'middle',
          legendOffset: 40,
          tickRotation: 0,
          format: (value: string) => formatDate(value),
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: chartWidth < 500 ? 8 : 10,
              },
            },
          },
        }}
        axisLeft={null}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        tooltip={DefaultHistoTooltip}
        layers={[
          'grid',
          'axes',
          'bars',
          CustomBarLabel(
            chartWidth,
            !!options?.percents,
            options?.withFixedValue
          ),
          ...(xAxis ? [CustomZeroLineLayer] : []),
          ...(extraLineData ? [CustomLineTooltip(extraLineData, keys[0])] : []),
          'markers',
          'legends',
        ]}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -80,
            itemsSpacing: 80,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'right-to-left',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
