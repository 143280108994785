import ReactGA from 'react-ga4';

export const trackEvent = (eventName: string, params: any) => {
  ReactGA.event(eventName, params);
};

interface TrackingParams {
  userName: string;
  companyName?: string;
  userType: string;
  dataType?: string;
}

export const trackRegistrationStart = (
  businessModel: string,
  experience: string,
  needs: string[],
  fullName: string
) => {
  return ReactGA.event({
    category: 'Registration',
    action: 'registration_start',
    label: `Business model: ${businessModel}, Experience: ${experience}, Needs: ${needs.join(
      '-'
    )}, Full Name: ${fullName}`,
  });
};

export const trackVideoWatch = (stepNumber: number) => {
  if (stepNumber === 2) {
    return ReactGA.event({
      category: 'Registration',
      action: 'intro_video_watched',
      label: `User wacthed video How we see financial management at Fuel`,
    });
  } else if (stepNumber === 3) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'PL_video_watched',
      label: `User watched video Managerial Profit and Loss (P&L)`,
    });
  } else if (stepNumber === 4) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'FP_video_watched',
      label: `User watched video Financial Planning`,
    });
  } else if (stepNumber === 5) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'PA_video_watched',
      label: `User watched video Dashboard & PA`,
    });
  }
};

export const trackRegistrationFilled = () => {
  return ReactGA.event({
    category: 'Registration',
    action: 'registration_filled',
    label: 'User filled registration form',
  });
};

export const trackVideoTutorialOpened = (stepNumber: number) => {
  if (stepNumber === 3) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'PL_video_opened',
      label: `User goes to the 3/5 step`,
    });
  } else if (stepNumber === 4) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'FP_video_opened',
      label: `User goes to the 4/5 step`,
    });
  } else if (stepNumber === 5) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'PA_video_opened',
      label: `User goes to the 5/5 step`,
    });
  }
};

export const trackOpenFile = (name: string) => {
  if (name.includes('Managerial P&L')) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_MPL',
      label: 'User opens Managerial Profit and Loss (P&L) file',
    });
  } else if (name.includes('Financial Planning')) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_FP',
      label: 'User opens Financial Planning file',
    });
  } else if (name.includes('Dashboard')) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_DB',
      label: 'User opens Dashboard file',
    });
  } else if (name.includes('Plan / Actual')) {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_PA',
      label: 'User opens Plan / Actual file',
    });
  }
};

export const trackOpenCabinetVideo = (name: string) => {
  if (name === 'How we see financial management at Fuel') {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_HWSvideo',
      label: 'User opens How we see financial management at Fuel video',
    });
  } else if (name === 'Managerial Profit and Loss (P&L)') {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_MPLvideo',
      label: 'User opens Managerial Profit and Loss (P&L) video',
    });
  } else if (name === 'Financial Planning') {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_FPvideo',
      label: 'User opens Financial Planning video',
    });
  } else if (name === 'Dashboard and Plan / Actual') {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_DPAvideo',
      label: 'User opens Dashboard and Plan / Actual video',
    });
  } else if (name === 'Cash flow, Balance Sheet') {
    return ReactGA.event({
      category: 'Cabinet',
      action: 'open_CBS',
      label: 'User opens Cash flow, Balance Sheet video',
    });
  }
};

export const trackBookDemoCall = () => {
  return ReactGA.event({
    category: 'Cabinet',
    action: 'book_demo_call',
    label: 'User books demo call',
  });
};

export const trackConnectQB = () => {
  return ReactGA.event({
    category: 'Cabinet',
    action: 'connect_QB',
    label: 'User presses Connect to Quickbooks button',
  });
};

export const trackPayInfoLog = () => {
  return ReactGA.event({
    category: 'Payment info',
    action: 'pay_info_log',
    label: 'User presses Pay info button',
  });
};

export const trackCancelSubscription = () => {
  return ReactGA.event({
    category: 'Payment info',
    action: 'cancel_sub',
    label: 'User presses Cancel Subscription',
  });
};

export const trackInvoicesLog = () => {
  return ReactGA.event({
    category: 'Payment info',
    action: 'invoices_log',
    label: 'User presses Invoices link',
  });
};

export const trackChangeCard = () => {
  return ReactGA.event({
    category: 'Payment info',
    action: 'change_card',
    label: 'User presses Change card button',
  });
};

export const trackRenewSubscription = () => {
  return ReactGA.event({
    category: 'Payment info',
    action: 'renew_sub',
    label: 'User presses Renew subscription button',
  });
};

export const trackTermsLog = () => {
  return ReactGA.event({
    category: 'Footer',
    action: 'terms_log',
    label: 'User presses Terms button',
  });
};

export const trackPrivacyLog = () => {
  return ReactGA.event({
    category: 'Footer',
    action: 'privacy_log',
    label: 'User presses Privacy button',
  });
};

export const trackContactUsLog = () => {
  return ReactGA.event({
    category: 'Footer',
    action: 'contact_us_log',
    label: 'User presses Contact us button',
  });
};

export const trackReportBugLog = () => {
  return ReactGA.event({
    category: 'Footer',
    action: 'report_bug_log',
    label: 'User presses Report a bug button',
  });
};

export const trackOpenUpdates = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('open_Updates', {
    category: 'Updates',
    action: 'User opens view all Updates',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddUpdate = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_Updates', {
    category: 'Updates',
    action: 'User added a new Update',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackOpenDocumentLink = ({
  documentName,
  userName,
  companyName,
  userType,
}: TrackingParams & { documentName: string }) => {
  trackEvent(`open_doc_${documentName}`, {
    category: 'Documents',
    action: `User opens document link ${documentName}`,
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddReportLink = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_doc', {
    category: 'Reports',
    action: 'User added a new Report link',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackDeleteReportLink = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('delete_doc', {
    category: 'Reports',
    action: 'User deleted a Report link',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackOpenDashboardPage = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('open_Dashboard', {
    category: 'Dashboard',
    action: 'User opens Dashboard page',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddDashboard = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_Dashboard', {
    category: 'Dashboard',
    action: 'User added a new Dashboard',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackDeleteDashboard = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('delete_Dashboard', {
    category: 'Dashboard',
    action: 'User deleted a Dashboard',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackOpenDataSourcesPage = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('open_DataSource', {
    category: 'DataSources',
    action: 'User opens DataSources page',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddIntegrationQB = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_integration_QB', {
    category: 'Integrations',
    action: 'User added a new Integration with QB',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddIntegrationStripe = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_integration_Stripe', {
    category: 'Integrations',
    action: 'User added a new Integration with Stripe',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddIntegrationHubspot = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_integration_Hubspot', {
    category: 'Integrations',
    action: 'User added a new Integration with Hubspot',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackAddIntegrationPayroll = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('add_integration_Payroll', {
    category: 'Integrations',
    action: 'User added a new Integration with Payroll',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackDeleteIntegration = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('delete_integration', {
    category: 'Integrations',
    action: 'User deleted an integration',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackStartImport = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('start_import', {
    category: 'Imports',
    action: 'User started an Import',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackOpenTemplate = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('open_GS', {
    category: 'Google Sheet Templates',
    action: 'User open Google Sheet template ',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackGSheetImport = ({
  userName,
  companyName,
  userType,
  dataType,
}: TrackingParams) => {
  trackEvent(`import_GSheet_${dataType}`, {
    category: 'Google Sheet',
    action: `User imported Google Sheet with ${dataType} data`,
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackDeleteGSheetConnection = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('delete_GSheet', {
    category: 'GSheet',
    action: 'User deleted gsheet connection',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackLogout = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('Logout', {
    category: 'User',
    action: 'User pressed log out',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackChangeCompany = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('change_company', {
    category: 'User',
    action: 'User switched to the other company',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackPressTerms = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('terms_log', {
    category: 'Footer',
    action: 'User presses Terms',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackPressPrivacy = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('privacy_log', {
    category: 'Footer',
    action: 'User presses Privacy',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackPressContactUs = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('contact_us_log', {
    category: 'Footer',
    action: 'User presses Contact us',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackReportBug = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('report_bug_log', {
    category: 'Footer',
    action: 'User presses Report a bug',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};

export const trackSettingPassword = ({
  userName,
  companyName,
  userType,
}: TrackingParams) => {
  trackEvent('set_password', {
    category: 'Registration',
    action: 'password_changed',
    label: 'The user submitted the new password',
    user_name: userName,
    company_name: companyName,
    user_type: userType,
  });
};
