import { useContext, useEffect, useState } from 'react';
import { CardFactory } from '../card.factory';
import { DashboardCard } from '../dashboard-cards/dashboard-card';
import { SnapshotDashboardData } from '../interfaces/snapshot-dashboard-data.interface';
import { SnapshotDashboardDataManager } from '../data-managers/snapshot-dashboard-data.manager';
import { RoleContext } from '../../../../context/role.context';
import { useParams } from 'react-router-dom';
import { useDashboardStructure } from '../dashboard-structure.hook';
import { fetchDashboardData } from './utils';

const fetchDataForCard = async (
  cardSettings: any,
  dashboardData: SnapshotDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dashboardDataManager = new SnapshotDashboardDataManager(dashboardData);
  dashboardDataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );

  const { identifier, name } = cardSettings;

  try {
    const dataResult = dashboardDataManager.getData(identifier);
    return {
      ...cardSettings,
      dataset: {
        name,
        dataset: dataResult,
      },
    };
  } catch (error) {
    return { ...cardSettings, name, dataset: {} };
  }
};

export const dashboardHeaderData = (
  dashboardData: SnapshotDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dataManager = new SnapshotDashboardDataManager(dashboardData);
  dataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );
  return {
    revenue: dataManager.getHeaderData('revenueActualMetrics'),
    margin: dataManager.getHeaderData('grossProfitActualMetrics'),
    opex: dataManager.getHeaderData('opexActualMetrics'),
    profitLoss: dataManager.getHeaderData('netProfitActualMetrics'),
    cashbalance: dataManager.getHeaderData('totalCashBalanceMetrics'),
    runwayMetrics: dataManager.getHeaderData('runwayMetrics'),
  };
};

export const useSnapshotDashboard = (
  dateRange: { startDate: Date; endDate: Date } | null
) => {
  const role = useContext(RoleContext);
  const { companyId } = useParams();
  const { getDashboardStructure } = useDashboardStructure('SNAPSHOT');
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<{
    plCards: DashboardCard<unknown, unknown>[];
    cashflowCards: DashboardCard<unknown, unknown>[];
    headerMetrics: any | null;
    ungrouppedCards: DashboardCard<unknown, unknown>[];
  }>({
    plCards: [],
    cashflowCards: [],
    headerMetrics: null,
    ungrouppedCards: [],
  });

  const getCardSettingsAndBuild = async () => {
    setLoading(true);
    try {
      const dashboardData = await fetchDashboardData<SnapshotDashboardData>(
        role,
        companyId,
        'Snapshot'
      );
      const plCards = [];
      const cashflowCards = [];
      const headerMetrics = dashboardHeaderData(dashboardData, dateRange);
      const ungrouppedCards = [];

      const factory = new CardFactory();
      const cardSettings = await getDashboardStructure();
      if (!cardSettings) {
        return;
      }
      for (const card of cardSettings) {
        const cardWithData = await fetchDataForCard(
          card,
          dashboardData,
          dateRange
        );

        const {
          type,
          identifier,
          layout,
          dataset,
          dashboardType,
          options,
          name,
          type: cardType,
        } = cardWithData;

        const newCard = factory.createCard(
          type,
          identifier,
          layout,
          dataset,
          options
        );

        newCard.setProperties({
          name,
          type: cardType,
          dashboardType,
        });

        if (newCard.component === null) {
          continue;
        }

        if (dashboardType === 'P_L') {
          plCards.push(newCard);
        } else if (dashboardType === 'CASHFLOW') {
          cashflowCards.push(newCard);
        } else {
          ungrouppedCards.push(newCard);
        }
      }

      setCards({
        plCards,
        cashflowCards,
        headerMetrics,
        ungrouppedCards,
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCardSettingsAndBuild();
    }

    return () => {
      isMounted = false;
    };
  }, [dateRange]);

  return {
    cards,
    loading,
  };
};
