import BarChartByCategory from '../../../../pages/dashboard/components/cards-content/bar-chart-category.componen';
import { CombinedChartComponent } from '../../../../pages/dashboard/components/cards-content/combined-chart/combined-chart';
import { Histogram } from '../../../../pages/dashboard/components/cards-content/histogram/histogram.component';
import { HistogramDataset } from '../interfaces/dashboard-card.type';
import {
  DashboardCard,
  NoDataAvailableComonent,
  withWrap,
} from './dashboard-card';

export interface HistogramProperties {
  name?: string;
  dataset?: HistogramDataset;
  identifier?: string;
}

export class HistogramCard extends DashboardCard<
  HistogramProperties,
  {
    colors?: string;
    percents?: boolean;
  }
> {
  constructor(
    identifier: string,
    layout?: any,
    data?: HistogramProperties,
    options?: { colors: string; enableLine: boolean }
  ) {
    super(identifier, { ...layout, minH: 2 }, data, options);
    this.component = (
      <div
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          padding: '16px',
        }}
      >
        <p>{data?.name}</p>
        <Histogram
          name={data?.name}
          identifier={identifier}
          dataset={data?.dataset}
          options={options}
        />
      </div>
    );
  }
}

export class CombinedChartCard extends DashboardCard<any, any> {
  constructor(identifier: string, layout: any, data: any, options: any) {
    super(identifier, layout);
    this.component = withWrap(
      <>
        <p>{data.name}</p>
        <CombinedChartComponent data={data} options={options} />
      </>
    );
  }
}

export class BarChartByCategoryCard extends DashboardCard<
  { dataset: HistogramDataset },
  null
> {
  constructor(
    identifier: string,
    layout: any,
    data: { name: string; dataset: HistogramDataset | null },
    options: { colors?: string[]; percents?: boolean } = {}
  ) {
    super(identifier, layout);

    this.component = data.dataset ? (
      withWrap(
        <>
          <p>{data.name}</p>
          <BarChartByCategory
            dataset={data.dataset}
            options={options}
            name={data.name}
            identifier={identifier}
          />
        </>
      )
    ) : (
      <NoDataAvailableComonent name={data.name} />
    );
  }
}
