import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { useState } from 'react';

export const UpdatedStackedChart = () => {
  const initialData = [
    {
      name: 'Jan',
      value1: 4200,
      value2: 3100,
      value3: 0,
      value4: 500,
      value5: 100,
    },
    {
      name: 'Feb',
      value1: 5300,
      value2: 2200,
      value3: 1500,
      value4: 11,
      value5: 400,
    },
    {
      name: 'Mar',
      value1: 7600,
      value2: 4500,
      value3: 2200,
      value4: 700,
      value5: 0,
    },
    {
      name: 'Apr',
      value1: 9800,
      value2: 6700,
      value3: 3000,
      value4: 0,
      value5: 1200,
    },
    {
      name: 'May',
      value1: 4500,
      value2: 2900,
      value3: 0,
      value4: 20000,
      value5: 300,
    },
    {
      name: 'Jun',
      value1: 6300,
      value2: 0,
      value3: 3100,
      value4: 800,
      value5: 0,
    },
    {
      name: 'Jul',
      value1: 5400,
      value2: 4800,
      value3: 1300,
      value4: 600,
      value5: 500,
    },
    {
      name: 'Aug',
      value1: 11200,
      value2: 6900,
      value3: 4000,
      value4: 0,
      value5: 900,
    },
    {
      name: 'Sep',
      value1: 8700,
      value2: 4200,
      value3: 0,
      value4: 1000,
      value5: 700,
    },
    {
      name: 'Oct',
      value1: 7600,
      value2: 3400,
      value3: 2900,
      value4: 0,
      value5: 800,
    },
    {
      name: 'Nov',
      value1: 5200,
      value2: 2900,
      value3: 1500,
      value4: 300,
      value5: 400,
    },
    {
      name: 'Dec',
      value1: 5000,
      value2: 7800,
      value3: 0,
      value4: 2000,
      value5: 600,
    },
  ];

  const keys = ['value1', 'value2', 'value3', 'value4', 'value5'];
  const colors = ['#804FE6', '#AB5AFB', '#C889DC', '#C4569D', '#E06999'];

  const [sortedKeys, setSortedKeys] = useState<string[]>(keys);

  const calculateSortedKeys = () => {
    const summedValues = keys.map((key) => ({
      key,
      sum: initialData.reduce((acc, curr) => acc + (curr[key] || 0), 0),
    }));

    const sorted = summedValues
      .sort((a, b) => b.sum - a.sum)
      .map((item) => item.key);

    setSortedKeys(sorted);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className="drag-cancel"
    >
      <button onClick={calculateSortedKeys}>Resort keys</button>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={initialData}
          margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
        >
          <XAxis dataKey="name" />
          <Tooltip />
          <Legend layout="horizontal" verticalAlign="top" align="right" />
          {sortedKeys.map((key, index) => (
            <Bar key={key} dataKey={key} stackId="a" fill={colors[index]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
