import {
  DashboardDataItem,
  DashboardDataItemWithCategory,
} from './dashboard-data-item.interface';

export interface PlDashboardData {
  revenueActualMetrics: DashboardDataItem[];
  ebitdaActualMetrics: DashboardDataItem[];
  runwayMetrics: DashboardDataItem[];
  opexActualMetrics: DashboardDataItem[];
  netProfitActualMetrics: DashboardDataItem[];
  netProfitProjectedMetrics: DashboardDataItem[];
  netProfitMarginMetrics: DashboardDataItem[];
  revenueProjectedMetrics: DashboardDataItem[];
  otherIncomeActualMetrics: DashboardDataItem[];
  costOfSalesActualMetrics: DashboardDataItem[];
  costOfSalesProjectedMetrics: DashboardDataItem[];
  otherExpensesActualMetrics: DashboardDataItem[];
  revenueByCategoriesMetrics: DashboardDataItemWithCategory[];
  revenueGrowthActualMetricsAsPercent: DashboardDataItem[];
  revenueGrowthProjectedMetricsAsPercent: DashboardDataItem[];
  grossProfitActualMetrics: DashboardDataItem[];
  grossProfitMarginActualMetrics: DashboardDataItem[];
  grossProfitMarginProjectedMetrics: DashboardDataItem[];
  cogsGrowthMetrics: DashboardDataItem[];
  opexGrowthMetrics: DashboardDataItem[];
  revenueGrowthActualMetricsAsSum: DashboardDataItem[];
  revenueGrowthProjectedMetricsAsSum: DashboardDataItem[];
}

export enum PlDashboardDataType {
  PROJECTED_ACTUAL_REVENUE = 'PROJECTED_ACTUAL_REVENUE',
  PROJECTED_ACTUAL_NET_PROFIT = 'PROJECTED_ACTUAL_NET_PROFIT',
  PL_WATERFALL = 'PL_WATERFALL',
  // PL_STRUCTURE = 'PL_STRUCTURE',
  PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN = 'PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN',
  PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT = 'PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT',
  PROJECTED_ACTUAL_REVENUE_GROWTH_SUM = 'PROJECTED_ACTUAL_REVENUE_GROWTH_SUM',
  PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN = 'PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN',
  PROJECTED_ACTUAL_COGS = 'PROJECTED_ACTUAL_COGS',
  // PL_COGS_TABLE = 'PL_COGS_TABLE',
  REVENUE_COGS_GROWTH = 'REVENUE_COGS_GROWTH',
  // PROJECTED_ACTUAL_OPEX_BREAKDOWN = 'PROJECTED_ACTUAL_OPEX_BREAKDOWN',
  OPEX_SUMMARY = 'OPEX_SUMMARY',
  REVENUE_OPEX_GROWTH = 'REVENUE_OPEX_GROWTH',
  // PROJECTED_ACTUAL_TEAM_BREAKDOWN = 'PROJECTED_ACTUAL_TEAM_BREAKDOWN',
  // PROJECTED_ACTUAL_TEAM_BREAKDOWN_TABLE = 'PROJECTED_ACTUAL_TEAM_BREAKDOWN_TABLE',
  EBITDA = 'EBITDA',
  NET_PROFIT_MARGIN = 'NET_PROFIT_MARGIN',
}
