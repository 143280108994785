import { useContext, useEffect, useState } from 'react';
import { CardFactory } from '../card.factory';
import { DashboardCard } from '../dashboard-cards/dashboard-card';
import { PlDashboardData } from '../interfaces/pl-dashboard-data.interface';
import { PlDashboardDataManager } from '../data-managers/pl-dashboard-data.manager';
import { RoleContext } from '../../../../context/role.context';
import { useParams } from 'react-router-dom';
import { useDashboardStructure } from '../dashboard-structure.hook';
import { fetchDashboardData } from './utils';

const fetchDataForCard = async (
  cardSettings: any,
  dashboardData: PlDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dashboardDataManager = new PlDashboardDataManager(dashboardData);
  dashboardDataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );

  const { identifier, name } = cardSettings;

  try {
    const dataResult = dashboardDataManager.getData(identifier);

    return {
      ...cardSettings,
      dataset: {
        name,
        dataset: dataResult,
      },
    };
  } catch (error) {
    return { ...cardSettings, name, dataset: {} };
  }
};

export const dashboardHeaderData = (
  dashboardData: PlDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dataManager = new PlDashboardDataManager(dashboardData);
  dataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );
  return {
    revenue: dataManager.getHeaderData('revenueActualMetrics'),
    grossProfit: dataManager.getHeaderData('grossProfitMarginActualMetrics'),
    opex: dataManager.getHeaderData('opexActualMetrics'),
    ebitda: dataManager.getHeaderData('ebitdaActualMetrics'),
    netProfitSum: dataManager.getHeaderData('netProfitActualMetrics'),
    netProfitPercent: dataManager.getHeaderData('netProfitMarginMetrics'),
  };
};

export const usePlDashboard = (
  dateRange: { startDate: Date; endDate: Date } | null
) => {
  const role = useContext(RoleContext);
  const { companyId } = useParams();
  const { getDashboardStructure } = useDashboardStructure('PL');
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<{
    pLStatementCards: DashboardCard<unknown, unknown>[];
    revenueCards: DashboardCard<unknown, unknown>[];
    grossProfitMarginAndCogsCards: DashboardCard<unknown, unknown>[];
    operationalExpensesCards: DashboardCard<unknown, unknown>[];
    ebitdaNetProfitCards: DashboardCard<unknown, unknown>[];
    headerMetrics: any | null;
  }>({
    pLStatementCards: [],
    revenueCards: [],
    grossProfitMarginAndCogsCards: [],
    operationalExpensesCards: [],
    ebitdaNetProfitCards: [],
    headerMetrics: null,
  });

  const getCardSettingsAndBuild = async () => {
    setLoading(true);
    try {
      const dashboardData = await fetchDashboardData<PlDashboardData>(
        role,
        companyId,
        'PL'
      );
      const pLStatementCards = [];
      const revenueCards = [];
      const grossProfitMarginAndCogsCards = [];
      const operationalExpensesCards = [];
      const ebitdaNetProfitCards = [];
      const headerMetrics = dashboardHeaderData(dashboardData, dateRange);

      const factory = new CardFactory();
      const cardSettings = await getDashboardStructure();
      if (!cardSettings) {
        return;
      }

      for (const card of cardSettings) {
        const cardWithData = await fetchDataForCard(
          card,
          dashboardData,
          dateRange
        );
        const {
          type,
          identifier,
          layout,
          dataset,
          dashboardType,
          options,
          name,
          type: cardType,
        } = cardWithData;

        if (
          dataset.dataset?.data &&
          dataset.dataset?.data?.length === 0 &&
          !['SummaryCard', 'ComparisonSummaryCard', 'WaterfallCard'].includes(
            type
          )
        ) {
          continue;
        }

        if (
          dataset.dataset?.rows &&
          dataset.dataset?.rows?.length === 0 &&
          !['TopCategoriesBalanceCard'].includes(type)
        ) {
          continue;
        }

        const newCard = factory.createCard(
          type,
          identifier,
          layout,
          dataset,
          options
        );

        newCard.setProperties({
          name,
          type: cardType,
          dashboardType,
        });

        if (newCard.component === null) {
          continue;
        }

        switch (dashboardType) {
          case 'PROFIT AND LOSS STATEMENT (P&L)':
            pLStatementCards.push(newCard);
            break;
          case 'REVENUE':
            revenueCards.push(newCard);
            break;
          case 'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)':
            grossProfitMarginAndCogsCards.push(newCard);
            break;
          case 'OPERATIONAL EXPENSES (OPEX)':
            operationalExpensesCards.push(newCard);
            break;
          case 'EBITDA & NET PROFIT':
            ebitdaNetProfitCards.push(newCard);
            break;
          default:
            pLStatementCards.push(newCard);
            break;
        }
      }

      setCards({
        pLStatementCards,
        revenueCards,
        grossProfitMarginAndCogsCards,
        operationalExpensesCards,
        ebitdaNetProfitCards,
        headerMetrics,
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getCardSettingsAndBuild();
    }

    return () => {
      isMounted = false;
    };
  }, [dateRange]);

  return {
    cards,
    loading,
  };
};
