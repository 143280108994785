import { useContext, useEffect, useState } from 'react';
import { CardFactory } from '../card.factory';
import { DashboardCard } from '../dashboard-cards/dashboard-card';
import { SalesMarketingDashboardDataManager } from '../data-managers/sales-marketing-dashboard-data.manager';
import { RoleContext } from '../../../../context/role.context';
import { useParams } from 'react-router-dom';
import { useDashboardStructure } from '../dashboard-structure.hook';
import { SalesMarketingDashboardData } from '../interfaces/sales-marketing-dashboard-data.interface';
import { fetchDashboardData } from './utils';

const fetchDataForCard = async (
  cardSettings: any,
  dashboardData: SalesMarketingDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dashboardDataManager = new SalesMarketingDashboardDataManager(
    dashboardData
  );
  dashboardDataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );

  const { identifier, name } = cardSettings;

  try {
    const dataResult = dashboardDataManager.getData(identifier);
    return {
      ...cardSettings,
      dataset: {
        name,
        dataset: dataResult,
      },
    };
  } catch (error) {
    return { ...cardSettings, name, dataset: {} };
  }
};

export const dashboardHeaderData = (
  dashboardData: SalesMarketingDashboardData,
  dateRange: {
    startDate: Date;
    endDate: Date;
  } | null
) => {
  const dataManager = new SalesMarketingDashboardDataManager(dashboardData);
  dataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );
  return {
    salesMarketingExpenses: dataManager.getHeaderData('acquistionCostsMetrics'),
    newRevenue: dataManager.getHeaderData('newRevenueActualMetrics'),
    wonDeals: dataManager.getHeaderData('newWonCustomersMetrics'),
    mql: dataManager.getHeaderData('mqlCustomersMetrics'),
    conversionMqlWon: dataManager.getHeaderData(
      'mqlWonAccumulatedConversionRateMetrics'
    ),
    ltvCac: dataManager.getHeaderData('ltvCacMetrics'),
  };
};

export const useSalesMarketingDashboard = (
  dateRange: { startDate: Date; endDate: Date } | null
) => {
  const role = useContext(RoleContext);
  const { companyId } = useParams();
  const { getDashboardStructure } = useDashboardStructure('SALES_MARKETING');
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<{
    salesMarketingOutcomesCards: DashboardCard<unknown, unknown>[];
    leadGenerationCards: DashboardCard<unknown, unknown>[];
    headerMetrics: any | null;
    salesCards: DashboardCard<unknown, unknown>[];
    sourcesCards: DashboardCard<unknown, unknown>[];
  }>({
    salesMarketingOutcomesCards: [],
    leadGenerationCards: [],
    headerMetrics: null,
    salesCards: [],
    sourcesCards: [],
  });

  const getCardSettingsAndBuild = async () => {
    setLoading(true);
    try {
      const dashboardData =
        await fetchDashboardData<SalesMarketingDashboardData>(
          role,
          companyId,
          'SalesMarketing'
        );

      const salesMarketingOutcomesCards = [];
      const leadGenerationCards = [];
      const salesCards = [];
      const sourcesCards = [];
      const headerMetrics = dashboardHeaderData(dashboardData, dateRange);

      const factory = new CardFactory();
      const cardSettings = await getDashboardStructure();
      if (!cardSettings) {
        return;
      }
      for (const card of cardSettings) {
        const cardWithData = await fetchDataForCard(
          card,
          dashboardData,
          dateRange
        );

        const {
          type,
          identifier,
          layout,
          dataset,
          dashboardType,
          options,
          name,
          type: cardType,
        } = cardWithData;

        const newCard = factory.createCard(
          type,
          identifier,
          layout,
          dataset,
          options
        );

        newCard.setProperties({
          name,
          type: cardType,
          dashboardType,
        });

        if (newCard.component === null) {
          continue;
        }

        if (dashboardType === 'SM_OUTCOMES') {
          salesMarketingOutcomesCards.push(newCard);
        } else if (dashboardType === 'LEAD_GENERATION') {
          leadGenerationCards.push(newCard);
        } else if (dashboardType === 'SALES') {
          salesCards.push(newCard);
        } else if (dashboardType === 'SOURCES') {
          sourcesCards.push(newCard);
        }
      }

      setCards({
        salesMarketingOutcomesCards,
        leadGenerationCards,
        headerMetrics,
        salesCards,
        sourcesCards,
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCardSettingsAndBuild();
    }

    return () => {
      isMounted = false;
    };
  }, [dateRange]);

  return {
    cards,
    loading,
  };
};
