import { useContext, useEffect, useState } from 'react';
import { CardFactory } from '../card.factory';
import { DashboardCard } from '../dashboard-cards/dashboard-card';
import { CashflowDashboardData } from '../interfaces/cashflow-dashboard-data.interface';
import { CashflowDashboardDataManager } from '../data-managers/cashflow-dashboard-data.manager';
import { ApiInstance } from '../../../../tools/api';
import { RoleContext } from '../../../../context/role.context';
import { useParams } from 'react-router-dom';
import { useDashboardStructure } from '../dashboard-structure.hook';

export const fetchDashboardData = async <T>(
  role: any,
  companyId: string | number | undefined,
  categoryType: string
): Promise<T> => {
  const apiInstance = new ApiInstance().instance;

  const endpoint =
    role.userType === 'MANAGER' && companyId
      ? `/manager/companies/${companyId}/dashboard/metrics?categoryType=${categoryType}`
      : `/api/dashboard/metrics?categoryType=${categoryType}`;

  const result = await apiInstance.get(endpoint);
  return result.data as T;
};

export const fetchDataForCard = async <
  TDashboardData,
  TDashboardManager extends {
    setDateRange: (startDate: Date | null, endDate: Date | null) => void;
    getData: (identifier: string) => any;
  }
>(
  cardSettings: { identifier: string; name: string },
  dashboardData: TDashboardData,
  dateRange: { startDate: Date; endDate: Date } | null,
  DashboardManager: new (data: TDashboardData) => TDashboardManager
) => {
  const dashboardDataManager = new DashboardManager(dashboardData);

  dashboardDataManager.setDateRange(
    dateRange?.startDate || null,
    dateRange?.endDate || null
  );

  const { identifier, name } = cardSettings;

  try {
    const dataResult = dashboardDataManager.getData(identifier);

    return {
      ...cardSettings,
      dataset: {
        name,
        dataset: dataResult,
      },
    };
  } catch (error) {
    console.error('Error fetching data for card:', error);

    return { ...cardSettings, name, dataset: {} };
  }
};
